import * as CalistaConstant from 'src/constants/CalistaConstant'
import CommonUtils from 'src/views/common/utils/Utils'

const Page = {
  BookingDetail: 'BookingDetail',
  BookingList: 'BookingList'
}
const Action = {
  cancel: 'cancel',
  copy: 'copy',
  delete: 'delete',
  edit: 'edit',
  amend: 'amend',
  archive: 'archive',
  viewSI: 'viewSI',
  createSI: 'createSI',
  trackContainers: 'trackContainers',
  approve: 'approve',
  reject: 'reject',
  helpdeskAmendBookingStatus: 'helpdeskAmendBookingStatus'
}
const BookingStatus = [
  {
    id: 5101,
    name: 'Pending Approval',
    color: '#5E81F433',
    actions: [Action.cancel, Action.copy],
    actionsH: [Action.cancel, Action.copy],
    carrierActions: [Action.edit],
    carrierActionsH: [Action.reject, Action.amend, Action.approve]
  },
  {
    id: 5102,
    name: 'Confirmed',
    color: '#7CE7AC4D',
    actions: [
      Action.createSI,
      Action.viewSI,
      Action.trackContainers,
      Action.amend,
      Action.copy,
      Action.archive,
      Action.cancel,
    ],
    actionsH: [
      Action.cancel,
      Action.archive,
      Action.copy,
      Action.amend,
      Action.trackContainers,
      Action.viewSI,
      Action.createSI,
      Action.helpdeskAmendBookingStatus
    ],
    carrierActions: [Action.edit, Action.archive],
    carrierActionsH: [Action.archive, Action.amend]
  },
  {
    id: 5103,
    name: 'Declined',
    color: '#FF808B4D',
    actions: [Action.archive, Action.copy],
    actionsH: [Action.archive, Action.copy, Action.helpdeskAmendBookingStatus],
    carrierActions: [Action.archive],
    carrierActionsH: [Action.archive]
  },
  {
    id: 5104,
    name: 'Cancelled',
    color: '#1C1D211A',
    actions: [Action.archive, Action.copy],
    actionsH: [Action.archive, Action.copy, Action.helpdeskAmendBookingStatus],
    carrierActions: [Action.archive],
    carrierActionsH: [Action.archive]
  },
  {
    id: 5105,
    name: 'Amended',
    color: '#9698D64D',
    actions: [
      Action.copy,
      Action.cancel,
      Action.viewSI,
      Action.trackContainers
    ],
    actionsH: [
      Action.copy,
      Action.cancel,
      Action.viewSI,
      Action.trackContainers
    ],
    carrierActions: [Action.edit],
    carrierActionsH: [Action.reject, Action.amend, Action.approve]
  },
  {
    id: 5107,
    name: 'Draft',
    color: '#8996AF41',
    actions: [Action.delete, Action.copy, Action.edit],
    actionsH: [Action.delete, Action.copy, Action.edit]
  }
]
function getBookingStatusById(id) {
  return BookingStatus.find((b) => b.id == id)
}
function getBookingStatusByName(name) {
  return BookingStatus.find((b) => b.name.toUpperCase() == name.toUpperCase())
}

const AmendButtonShowStatus = [
  CalistaConstant.BOOKING_STATUS_AMENDED,
  CalistaConstant.BOOKING_STATUS_APPROVED,
  CalistaConstant.BOOKING_STATUS_PENDING_APPROVAL
]

const ApproveButtonShowStatus = [
  CalistaConstant.BOOKING_STATUS_PENDING_APPROVAL,
  CalistaConstant.BOOKING_STATUS_AMENDED
]

const RejectButtonShowStatus = [
  CalistaConstant.BOOKING_STATUS_PENDING_APPROVAL,
  CalistaConstant.BOOKING_STATUS_AMENDED
]

function getShowButtonForCarrier(bookingStatus) {
  return {
    showApprove: ApproveButtonShowStatus.includes(bookingStatus + '')
      ? ''
      : 'none',
    showReject: RejectButtonShowStatus.includes(bookingStatus + '')
      ? ''
      : 'none',
    showAmend: AmendButtonShowStatus.includes(bookingStatus + '') ? '' : 'none'
  }
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0
}

const dateTimeFields = [
  'polEta',
  'polEtd',
  'podEta',
  'podEtd',
  'emptyPickupDatePickup',
  'shipFromDatePositioning',
  'shipFromDatePickup',
  'shipToDateDelivery',
  'placeOfReceiptEtd',
  'placeOfDeliveryEta'
]

const dateFields = [
  'cargoReadyDate',
  'cargoDeliveryDate',
  'placeOfReceiptEtd',
  'placeOfDeliveryEta'
]

const nonNullableArrayFields = [
  'bookingCargoes',
  'bookingDGCargoes',
  'bookingDocuments',
  'pendingBookingDocuments'
]

const scheduleRelatedField = ['vesselName', 'voyageNo', 'intVoyageNo']

function convertDate(data) {
  const dataArr = Object.entries(data)
  dataArr.map(([key, val]) => {
    if (dateTimeFields.includes(key)) {
      var dateToServer = CommonUtils.strictFormatDateAndConvertToGMT(
        val,
        CommonUtils.UI_FORMAT_DATE_TIME,
        null
      )

      data[key] = dateToServer
    } else if (dateFields.includes(key)) {
      if (val != null && val != '') {
        data[key] = CommonUtils.strictFormatDateAndConvertToGMT(
          val,
          CommonUtils.UI_FORMAT_DATE,
          null
        )
      }
    }

    return data
  })
}

const Utils = Object.freeze({
  Page,
  Action,
  BookingStatus,
  dateTimeFields,
  dateFields,
  nonNullableArrayFields,
  scheduleRelatedField,
  AmendButtonShowStatus,
  getBookingStatusById,
  getBookingStatusByName,
  isEmpty,
  convertDate,
  getShowButtonForCarrier
})
export default Utils
