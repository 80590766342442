
import DGCargoForEmergencyValidationSchema from 'src/views/freightbooking/shared/validation/DGCargoForEmergencyValidationSchema'
import DGCargoClassificationIdentificationValidationSchema from 'src/views/freightbooking/shared/validation/DGCargoClassificationIdentificationValidationSchema'
import DGCargoPackageWeightValidationSchema from 'src/views/freightbooking/shared/validation/DGCargoPackageWeightValidationSchema'
import DGCargoPhysicalChemicalValidationSchema from 'src/views/freightbooking/shared/validation/DGCargoPhysicalChemicalValidationSchema'
import DGCargoHazardousIdentificationValidationSchema from 'src/views/freightbooking/shared/validation/DGCargoHazardousIdentificationValidationSchema'
import CargoValidationSchema from 'src/views/freightbooking/shared/validation/CargoValidationSchema'


function useDGCargoDetailsMakeValidationSchema() {


  return DGCargoForEmergencyValidationSchema()
    .concat(DGCargoClassificationIdentificationValidationSchema())
    .concat(DGCargoPackageWeightValidationSchema())
    .concat(DGCargoPhysicalChemicalValidationSchema())
    .concat(DGCargoHazardousIdentificationValidationSchema())


}

export default useDGCargoDetailsMakeValidationSchema
