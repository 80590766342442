import FreightBookingValidationKeys from 'src/constants/locale/key/FreightBookingValidation'
import Namespace from 'src/constants/locale/Namespace'
import { useTranslation, constants } from 'cng-web-lib'


const {
    locale: {
        key: { CommonValidationMessageKeys }
    }
} = constants

const ValidationMessageTranslationText = () => {
    const { translate } = useTranslation([
        Namespace.FREIGHT_BOOKING_VALIDATION,
        Namespace.COMMON_VALIDATION_MSG,
    ])


    let requiredMessage = translate(
        Namespace.COMMON_VALIDATION_MSG,
        CommonValidationMessageKeys.REQUIRED
    )

    let dateTypeErrorMessage = translate(
        Namespace.COMMON_VALIDATION_MSG,
        CommonValidationMessageKeys.DATE_TYPE_ERROR
    )
    let invalidEmailMessage = translate(
        Namespace.COMMON_VALIDATION_MSG,
        CommonValidationMessageKeys.INVALID_EMAIL
    )

    let valueTooSmallMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.VALUE_TO_SMALL
    )

    let valueTooBigMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.VALUE_TOO_BIG
    )

    let numberErrorMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.NUMBER_ERROR
    )

    let numericErrorMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.NUMERIC_ERROR
    )

    let invalidStrMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.INVALID_STRING
    )


    let invalidAddressMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.INVALID_ADDRESS
    )

    let invalidBlankSpaceMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.INVALID_BLANK_SPACE
    )

    let invalidHSCode = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.INVALID_HSCODE
    )



    let IntegerOnlyMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.INTEGER_ONLY
    )


    let invalidPhoneMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.INVALID_PHONE
    )


    let PorPoDSameMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.POR_POD_SAME
    )

    let PolPodSameMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.POL_POD_SAME
    )

    let CargoReadyDateBeforeTodayMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.CARGO_READY_DATE_BEFORE_TODAY
    )

    let PoRETDBeforeTodayMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.PoR_ETD_BEFORE_TODAY
    )

    let POLETABeforeTodayMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.POL_ETA_BEFORE_TODAY
    )

    let CargoDeliveryDateBeforeCargoReadyDateMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.CARGO_DELIVERY_DATE_BEFORE_CARGO_READY_DATE
    )

    let POLETDBeforePOLETAMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.POL_ETD_BEFORE_POL_ETA
    )

    let PODETABeforeCargoReadyDateMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.POD_ETA_BEFORE_CARGO_READY_DATE
    )
    let PODETABeforePOLETDMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.POD_ETA_BEFORE_POL_ETD
    )
    let PODETDBeforeCargoReadyDateMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.POD_ETD_BEFORE_CARGO_READY_DATE
    )
    let PODETDBeforePOLETDMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.POD_ETD_BEFORE_POL_ETD
    )
    let PODETDBeforePODETAMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.POD_ETD_BEFORE_POD_ETA
    )

    let PODETAAfterCargoDeliveryDateMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.POD_ETA_AFTER_CARGO_DELIVERY_DATE_MSG
    )


    let FrbDateTypeErrorMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.FRB_DATE_TYPE_ERROR
    )
    let FrbDateTimeTypeErrorMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.FRB_DATE_TIME_TYPE_ERROR
    )

    let fourDigitOnly = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.FOUR_DIGIT_ONLY
    )

    let PODETAlaterThanCargoDeliveryDateMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.POD_ETA_LATER_THAN_CARGO_DELIVERY_DATE_MSG
    )

    let POLETDEarlierThanCargoReadyDateMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.POL_ETD_EARLIER_THAN_CARGO_READY_DATE_MSG
    )

    let invalidEmailListMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.INVALID_EMAIL_LIST
    )

    let exceedEmailListLengthMsg = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.EXCEED_EMAIL_LIST_LENGTH
    )

    let exceedEmailMaxLength = translate(
        Namespace.FREIGHT_BOOKING_VALIDATION,
        FreightBookingValidationKeys.EXCEED_EMAIL_MAX_LENGTH
    )

    



    return {
        requiredMessage,
        dateTypeErrorMessage,
        invalidEmailMessage,
        valueTooSmallMsg,
        valueTooBigMsg,
        numberErrorMsg,
        numericErrorMsg,
        invalidStrMsg,
        invalidAddressMsg,
        invalidBlankSpaceMsg,
        invalidHSCode,
        IntegerOnlyMsg,
        invalidPhoneMsg,

        PorPoDSameMsg,
        PolPodSameMsg,
        CargoReadyDateBeforeTodayMsg,
        PoRETDBeforeTodayMsg,
        POLETABeforeTodayMsg,
        CargoDeliveryDateBeforeCargoReadyDateMsg,
        POLETDBeforePOLETAMsg,
        PODETABeforeCargoReadyDateMsg,
        PODETABeforePOLETDMsg,
        PODETDBeforeCargoReadyDateMsg,
        PODETDBeforePOLETDMsg,
        PODETDBeforePODETAMsg,
        PODETAAfterCargoDeliveryDateMsg,
        FrbDateTypeErrorMsg,
        FrbDateTimeTypeErrorMsg,
        fourDigitOnly,
        PODETAlaterThanCargoDeliveryDateMsg,
        POLETDEarlierThanCargoReadyDateMsg,

        invalidEmailListMsg,
        exceedEmailListLengthMsg,
        exceedEmailMaxLength


    }
}

export default ValidationMessageTranslationText