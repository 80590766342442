import { Yup } from 'cng-web-lib'
import * as ValidationRegex from './ValidationRegex'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function DGCargoClassificationIdentificationValidationSchema() {


  const validationMessageTranslation = ValidationMessageTranslationText()

  function validateUNDGNumber(value, name) {
    if (!!value) {
      return String(value).match(ValidationRegex.undgRegex);
    } else {

      return true;
    }
  }


  return Yup.object({

    imoClass: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),
    undgNumber: Yup.string().required(validationMessageTranslation.requiredMessage).nullable()
      .test("undgNumber", validationMessageTranslation.fourDigitOnly, value => validateUNDGNumber(value)),
    properShippingName: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),

  })
}

export default DGCargoClassificationIdentificationValidationSchema

