import React, { useRef, useState, useEffect } from 'react'
import { components, useServices, DateTimeFormatter } from 'cng-web-lib'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import NewBookingFormProperties from './NewBookingFormProperties'
import Utils from '../shared/Utils'
import CommonUtils from 'src/views/common/utils/Utils'
import useNewBookingMakeValidationSchema from './useNewBookingMakeValidationSchema'
import * as CalistaConstant from 'src/constants/CalistaConstant'

const {
  form: { CngViewForm },
  table: { useDefaultNotification }
} = components

function CreateNewBookingPage({ showNotification }) {
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const { createRecord } = useServices()
  const [loading, setLoading] = useState(false)
  const translatedTextsObject = FreightBookingTranslationText()
  const [booking, setBooking] = useState([])
  const bookingId = useRef()
  const freightBookingId = useRef()
  const historyData = location.state
  const { fetchRecords } = useServices()
  const validationSchema = useNewBookingMakeValidationSchema()


  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)


  function onSubmit(data) {
    console.log('submit', data)

    const bookingToSubmit = JSON.parse(JSON.stringify(data));

    // Utils.convertDate(bookingToSubmit)
    //the format of file will get wrong after duplicate the json string,
    //hence need to setback from the data submitted from form
    bookingToSubmit.pendingBookingDocuments = data.pendingBookingDocuments
    bookingToSubmit.bookingDocuments = data.bookingDocuments

    if (bookingToSubmit.hazardous) {
      if (bookingToSubmit.bookingDGCargoes && bookingToSubmit.bookingDGCargoes.length > 0) {
        bookingToSubmit.bookingDGCargoes.map((dgCargo) => {
          if (dgCargo.dgDetails != null) {
            dgCargo.dgDetails.map(cargoDetails => {
              // console.log(cargoDetails.dateOfFumigation)
              cargoDetails.dateOfFumigation = CommonUtils.strictFormatDateAndConvertToGMT(cargoDetails.dateOfFumigation, CommonUtils.UI_FORMAT_DATE_TIME, "")
            })
          }
        })
      }

    }

    if (bookingToSubmit.bookingContainers != null) {
      const list = bookingToSubmit.bookingContainers
      list.forEach(container => {
        //clear reefer values as selected container was non-reefer and User does not want the values to remain if they came back to Create screen
        if (!container.reeferAvailable) {
          container.equipmentTemp = null
          container.tempUom = CalistaConstant.REEFER_TEMP_CEL
          container.superFreezerService = false
          container.gensetRequired = false
          container.inTransitColdSterilization = false
          container.equipmentControlledAtmosphereCO2 = null
          container.equipmentControlledAtmosphereN2 = null
          container.equipmentControlledAtmosphereO2 = null
          container.ventSetting = null
          container.equipmentAirFlow = null
          container.equipmentHumidity = null
          container.numberOfTempProbes = null
          container.numberOfUSDProbes = ''
          container.tempVariance = null
          container.equipmentComments = ''
        }
      })
    }


    bookingToSubmit.bookingId = data.bookingId == null ? bookingId.current : data.bookingId
    bookingToSubmit.freightBookingId = data.freightBookingId == null ? freightBookingId.current : data.freightBookingId

    console.log(bookingToSubmit)
    if (bookingToSubmit['saveDraft']) {
      //if there is document, save document first then get the doc id and save draft
      console.log(bookingToSubmit.pendingBookingDocuments)
      if (bookingToSubmit.pendingBookingDocuments && !Utils.isEmpty(bookingToSubmit.pendingBookingDocuments)) {

        uploadDocumentAndSaveDraft(bookingToSubmit)
      } else {

        saveDraft(bookingToSubmit)
      }

    } else {
      reviewBooking(bookingToSubmit)
    }

  }

  function uploadDocumentAndSaveDraft(data) {
    setLoading(true)
    console.log('uploadDocumentAndSaveDraft')

    var formData = new FormData();
    console.log(data.pendingBookingDocuments)

    data.pendingBookingDocuments.map((file, i) => {
      var fileBlob = file.file
      console.log(fileBlob)
      formData.append("fileContents", fileBlob); // name of the file upload box 

    })


    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      // setLoading(false)
      if (response.errorMessages != null) {

        showErrorNotification(response.errorMessages)

      } else {
        //showSuccessNotification(`${translatedTextsObject.saveDraftSuccess} ${response.freightBookingId}`)
        console.log(response)
        let bookingDocs = data.bookingDocuments || [] //to store the final booking documents
        if (response && response.length > 0) {
          response.map((file, i) => {
            let bookingDoc = {
              docType: data.pendingBookingDocuments[i].fields[0].value,
              filename: file.fileName,
              description: data.pendingBookingDocuments[i].fields[1].value,
              docId: file.id,
              filesize: file.fileSize
            }

            bookingDocs.push(bookingDoc)
          })
        }

        data.bookingDocuments = bookingDocs
        // data.bookingDocuments.map((file, i) => {
        //   file.docId = response[i].id
        //   file.filename = response[i].fileName


        // })

        console.log(data)
        saveDraft(data)
      }
    }

    function onError(error) {
      console.log('connection-request error', error.message)
      setLoading(false)
      showErrorNotification(CommonUtils.getErrorMessage(error))
    }

    createRecord.execute(
      FreightBookingApiUrls.DOCUMENT_UPLOAD,
      formData,
      onSuccess,
      onError
    )
  }

  function saveDraft(data) {
    setLoading(true)
    console.log('saveDraft')
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      setLoading(false)
      if (response.errorMessages != null) {
        // if (response.errorMessages.length > 0) {
        showErrorNotification(response.errorMessages)
        // }
      } else {
        showSuccessNotification(`${translatedTextsObject.saveDraftSuccess} ${response.freightBookingId}`)
        bookingId.current = response.bookingId
        freightBookingId.current = response.freightBookingId
        history.push({
          pathname: '/cal-freightbooking/my-booking'
        })
      }
    }

    function onError(error) {
      console.log('connection-request error', error.message)
      setLoading(false)
      showErrorNotification(CommonUtils.getErrorMessage(error))
    }

    createRecord.execute(
      FreightBookingApiUrls.SAVE_DRAFT,
      data,
      onSuccess,
      onError
    )
  }

  function reviewBooking(data) {
    setLoading(true)
    console.log('reviewBooking')
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      setLoading(false)
      if (response.errorMessages != null) {
        // alert('error')
        // if (response.errorMessages.length > 0) {
        showErrorNotification(response.errorMessages)
        // }
      } else {
        history.push({
          pathname: '/cal-freightbooking/create-new-booking/review',
          state: data
        })
      }
    }

    function onError(error) {
      console.log('connection-request error', error.message)
      setLoading(false)
      showErrorNotification(CommonUtils.getErrorMessage(error))
    }

    createRecord.execute(
      FreightBookingApiUrls.REVIEW_BOOKING,
      data,
      onSuccess,
      onError
    )
  }


  return (

    // use CngViewForm here for customizable button set
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <NewBookingFormProperties.FormFields
          disabled={false}
          showNotification={showNotification}
          loading={loading}
          onSubmitForm={onSubmit}
          validationSchema={validationSchema}
        />
      }
      formikProps={{
        ...NewBookingFormProperties.formikProps,
        validationSchema,


      }}
    // toClientDataFormat={PollingFormProperties.toClientDataFormat}
    // toServerDataFormat={PollingFormProperties.toServerDataFormat}
    />

  )
}

export default CreateNewBookingPage
