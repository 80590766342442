import * as CalistaConstant from 'src/constants/CalistaConstant'

import {
    Avatar,
    Box,
    Card,
    CardActionArea,
    Chip,
    Grid,
    Paper,
    Typography
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import Api from '../../shared/api'
import AuditTrailSummary from '../../components/AuditTrailSummary'
import AuditTrailTableComponent from 'src/components/auditTrail/AuditTrailTableComponent'
import AuditTrialRemarks from 'src/components/auditTrail/AuditTrailRemarks'
import BookingActionMenu from '../../components/BookingActionMenu'
import CommonUtils from 'src/views/common/utils/Utils'
import ComponentWrapper from '../../components/ComponentWrapper'
import DocumentDetails from '../../components/DocumentDetails'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import Hazardous from 'src/components/button/Hazardous.js'
import HeaderBarTop from '../../components/HeaderBarTop'
import Utils from '../../shared/Utils'
import ViewAuditDetailDialog from '../../components/ViewAuditDetailDialog'
import ViewBookingButtonComponent from 'src/views/helpdesk/viewbooking/ViewBookingButtonComponent'
import ViewBookingTypeComponent from '../../components/ViewBookingTypeComponent'
import ViewCargoComponent from '../../components/ViewCargoComponent'
import ViewContactComponent from '../../components/ViewContactComponent'
import ViewContainerComponent from '../../components/ViewContainerComponent'
import ViewDgCargoComponent from '../../components/ViewDgCargoComponent'
import ViewDocumentComponent from '../../components/ViewDocumentComponent'
import ViewLastModifiedComponent from '../../components/ViewLastModifiedComponent'
import ViewPaymentComponent from '../../components/ViewPaymentComponent'
import ViewScheduleComponent from '../../components/ViewScheduleComponent'
import ViewShipmentComponent from '../../components/ViewShipmentComponent'
import ViewShipperInfoComponent from '../../components/ViewShipperInfoComponent'
import apiParser from '../../shared/apiParser'
import QuickScroll from 'src/components/iconbutton/QuickScroll'
import HelpdeskBookingActionMenu from 'src/views/helpdesk/components/HelpdeskBookingActionMenu'

const {
    CngGridItem
} = components

function FormFields(props) {
    const { booking, ports, auditList, userCriteria } = props
    const translatedTextsObject = FreightBookingTranslationText()
    const [newDate, setNewDate] = useState('')
    const [prevDate, setPrevDate] = useState('')
    const [auditCompare, setAuditCompare] = useState({})
    const [isDialogOpen, setDialogOpen] = useState(false)

    const location = useLocation()
    const bookStatus = location.state.bookStatus

    const containers = booking.bookingContainers || []
    const cargos = booking.bookingCargoes || []
    const dgCargos = booking.bookingDGCargoes || []
    const documents = booking.bookingDocuments || []

    const [lastModifiedDate, setLastModifiedDate] = useState()
    const [lastModifiedText, setLastModifiedText] = useState()
    const [auditBooking, setAuditBooking] = useState({})
    const { fetchRecords } = useServices()
    let buttonLayout = {
        showEdit: true,
        showCopy: true,
        showDelete: true,
        showCancel: false,
        showArchive: false

    }

    useEffect(() => {
        switch (bookStatus.toString()) {
            case CalistaConstant.BOOKING_STATUS_APPROVED:
            case CalistaConstant.BOOKING_STATUS_AMENDED:
                setLastModifiedText(translatedTextsObject.bookingConfirmedOn)
                setLastModifiedDate(booking.updatedDate)
                break;
            case CalistaConstant.BOOKING_STATUS_CANCELLED:
            case CalistaConstant.BOOKING_STATUS_REJECTED:
            case CalistaConstant.BOOKING_STATUS_PENDING_APPROVAL:
                setLastModifiedText(translatedTextsObject.bookingRequestedOn)
                setLastModifiedDate(booking.submittedDate)
                break;
            case CalistaConstant.BOOKING_STATUS_DRAFT:
                setLastModifiedText(translatedTextsObject.bookingCreatedOn)
                setLastModifiedDate(booking.createdDate)
                break;
            default:
                break;
        }
    }, [props])

    useEffect(() => {
        if (auditList && auditList.length > 1 && bookStatus == CalistaConstant.BOOKING_STATUS_APPROVED) {
            let newVersion = auditList[0].frozenVersion
            let oldVersion = auditList[1].frozenVersion

            const versions = {
                oldVersion: oldVersion,
                newVersion: newVersion
            }
            Api.fetchAuditTrailCompare(fetchRecords, versions, onAuditTrailLoadSuccess)
        }
    }, [auditList])

    function onAuditTrailLoadSuccess(data) {

        setAuditBooking(data)
    }

    return (

        <Box id="top-level-box">
            <Paper>

                <HeaderBarTop booking={booking}></HeaderBarTop>
                <Box p={5} borderTop={1} borderBottom={1} className="border-light-blue" >
                    {
                        bookStatus == CalistaConstant.BOOKING_STATUS_APPROVED && (
                            <>
                                <Box pb={5}>
                                    <AuditTrialRemarks />
                                </Box>

                                <Box pb={5}>
                                    <AuditTrailSummary booking={auditBooking} auditList={auditList} ports={ports} />
                                </Box>
                            </>
                        )
                    }
                    <Grid container xs={12} sm={12} alignItems='center' style={{ marginBottom: 40 }}>
                        <Grid container xs={7}>
                            <ViewLastModifiedComponent text={lastModifiedText} date={lastModifiedDate} />
                        </Grid>
                        <Grid container xs direction='row' justify='flex-end' alignItems='center'>
                            <Box>
                                <img
                                    src={`${process.env.PUBLIC_URL}/static/images/Logo_CALISTA.svg`}
                                ></img>
                            </Box>
                            <Box className={'boldLabel'} >&nbsp;reference number: {booking.freightBookingId}</Box>
                            {
                                booking.hazardous && (
                                    <Box ml={1}>
                                        <Hazardous />
                                    </Box>
                                )
                            }
                        </Grid>
                    </Grid>

                    {/* {props.helpdesk? 
                         <Box pb={5}>
                            <ViewShipperInfoComponent.FormBody booking={booking}/> 
                        </Box>
                            : null
                    } */}

                    <Grid container xs={12} sm={12} justify="space-between">

                        <Grid item xs={4}>
                            {booking.shipmentType && (
                                <Box>
                                    <ViewBookingTypeComponent.FormBody bookingTypeData={booking} />
                                </Box>
                            )}
                        </Grid>
                        {
                            booking.carrierRemarks &&
                            <Grid item xs={6}>
                                <ComponentWrapper header={translatedTextsObject.carrierComments}>
                                    <Box px={2} py={3} style={CommonUtils.auditHighLight(auditBooking, ['carRemarks'])}>
                                        <Typography>{booking.carrierRemarks}</Typography>
                                    </Box>
                                </ComponentWrapper>
                            </Grid>
                        }
                    </Grid>
                    <Box pt={5}>
                        <ViewScheduleComponent.FormBody scheduleData={booking} ports={ports} showDetails
                            auditBooking={auditBooking} />
                    </Box>


                    {(booking.moveType == null) ?
                        (

                            <Box></Box>

                        )
                        : (<Box pt={5}>
                            <ViewShipmentComponent.FormBody shipmentData={booking} ports={ports} showDetails auditBooking={auditBooking} />
                        </Box>)}



                    <Box pt={5}>
                        <ViewContainerComponent.FormBody containers={containers} auditBooking={auditBooking} />
                    </Box>

                    {
                        cargos.length > 0 && (
                            <Box pt={5}>
                                <ViewCargoComponent.FormBody cargo={cargos} showDetails />
                            </Box>
                        )
                    }
                    {
                        dgCargos.length > 0 && (
                            <Box pt={5}>
                                <ViewDgCargoComponent.FormBody cargo={dgCargos} showDetails />
                            </Box>
                        )
                    }

                    {
                        documents.length > 0 && (
                            <Box pt={5}>
                                <ViewDocumentComponent.FormBody document={documents} auditBooking={auditBooking} allowDownload />
                            </Box>
                        )}
                    <Box pt={5}>
                        <ViewContactComponent.FormBody contactData={booking} auditBooking={auditBooking} />
                    </Box>
                    <Box pt={5}>
                        <ViewPaymentComponent.FormBody paymentData={booking} />
                    </Box>
                    <Box pt={5}>
                        <AuditTrailTableComponent.FormBody booking={booking} ports={ports} auditList={auditList} userCriteria={userCriteria}
                            setDialogOpen={(data) => setDialogOpen(data)}
                            setAuditCompare={(data) => setAuditCompare(data)}
                            setNewDate={(data) => setNewDate(data)}
                            setPrevDate={(data) => setPrevDate(data)} />
                        <ViewAuditDetailDialog
                            isDialogOpen={isDialogOpen}
                            auditCompare={auditCompare}
                            newDate={newDate}
                            prevDate={prevDate}
                            currentBooking={booking}
                            currentBookStatus={booking.bookingStatus}
                            closeDialog={() => setDialogOpen(false)}
                            ports={ports}
                        />
                    </Box>

                    <Box alignItems='center'>
                        {props.helpdesk ?
                            <HelpdeskBookingActionMenu booking = {booking} page = {Utils.Page.BookingDetail} {...props}/> :
                            <BookingActionMenu booking={booking}
                                page={Utils.Page.BookingDetail}
                                {...props} />
                        }
                    </Box>
                    <QuickScroll />
                </Box>
            </Paper>
        </Box>
    )
}

const MyBookingReviewApproved = Object.freeze({
    // formikProps: FORMIK_PROPS,
    FormFields: FormFields
    // toClientDataFormat: toClientDataFormat,
    // toServerDataFormat: toServerDataFormat
})


export default MyBookingReviewApproved