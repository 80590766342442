import { Yup } from 'cng-web-lib'
import * as ValidationRegex from './ValidationRegex'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function DGCargoPackageWeightValidationSchema() {


  const validationMessageTranslation = ValidationMessageTranslationText()

  function validateWeightVolumeNumber(value, name) {
    if (!!value) {
      return String(value).match(ValidationRegex.WeightVolumeRegx);
    } else {

      return true;
    }
  }

  function validateDGWeightVolumeNumber(value, name) {
    if (!!value) {
      return String(value).match(ValidationRegex.dgWeightVolumeRegx);
    } else {

      return true;
    }
  }

  return Yup.object({

    outerPackingQty: Yup.number().required(validationMessageTranslation.requiredMessage).integer(validationMessageTranslation.IntegerOnlyMsg).min(0, validationMessageTranslation.valueTooSmallMsg).max(9999999999, validationMessageTranslation.valueTooBigMsg).nullable(),
    outerPackingQtyUom: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),
    innerPackingQty: Yup.number().required(validationMessageTranslation.requiredMessage).integer(validationMessageTranslation.IntegerOnlyMsg).min(0, validationMessageTranslation.valueTooSmallMsg).max(9999999999, validationMessageTranslation.valueTooBigMsg).nullable(),
    innerPackingQtyUom: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),
    grossVolume: Yup.mixed().test("grossVolume", validationMessageTranslation.numericErrorMsg, value => validateDGWeightVolumeNumber(value)),
    grossWeight: Yup.mixed().test("grossWeight", validationMessageTranslation.numericErrorMsg, value => validateDGWeightVolumeNumber(value)),
    netVolume: Yup.mixed().test("netVolume", validationMessageTranslation.numericErrorMsg, value => validateDGWeightVolumeNumber(value)),
    netWeight: Yup.mixed().test("netWeight", validationMessageTranslation.numericErrorMsg, value => validateDGWeightVolumeNumber(value)),
    vgmWeight: Yup.mixed().test("vgmWeight", validationMessageTranslation.numericErrorMsg, value => validateDGWeightVolumeNumber(value)),
    quantity: Yup.number().integer(validationMessageTranslation.IntegerOnlyMsg).min(0, validationMessageTranslation.valueTooSmallMsg).max(99999, validationMessageTranslation.valueTooBigMsg).nullable(),

    limitedQty: Yup.number().integer(validationMessageTranslation.IntegerOnlyMsg).min(0, validationMessageTranslation.valueTooSmallMsg).max(9999999999, validationMessageTranslation.valueTooBigMsg)
      .when('limitedQtyUom', {
        is: value => value && value.length > 0,
        then: Yup.number().required(validationMessageTranslation.requiredMessage).nullable(),

        otherwise: Yup.number().nullable()

      }),
    limitedQtyUom: Yup.string().nullable()
      .test("limitedQty", validationMessageTranslation.requiredMessage, function (
        val
      ) {
        const { limitedQty } = this.parent;
        if (limitedQty) return val != null
        return true
      }),



    exceptedQty: Yup.number().integer(validationMessageTranslation.IntegerOnlyMsg).min(0, validationMessageTranslation.valueTooSmallMsg).max(9999999999, validationMessageTranslation.valueTooBigMsg)
      .when('exceptedQtyUom', {
        is: value => value && value.length > 0,
        then: Yup.number().required(validationMessageTranslation.requiredMessage).nullable(),

        otherwise: Yup.number().nullable()

      }),

    exceptedQtyUom: Yup.string().nullable()
      .test("exceptedQty", validationMessageTranslation.requiredMessage, function (
        val
      ) {
        const { exceptedQty } = this.parent;
        if (exceptedQty) return val != null
        return true
      }),
  })
}

export default DGCargoPackageWeightValidationSchema

