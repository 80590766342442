import { Yup, constants } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import moment from 'moment'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  let invalidEmailMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.INVALID_EMAIL
  )

  //const phoneRegx = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\\s\\./0-9]*$/;
  const phoneRegx = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/
  const excludeSpecialCharRegx = /^[^~$^+=|<>]*$/
  const excludeSpecialCharRegx2 = /^[^<+>^`~$^=|]*$/
  const addressMaxRegx = /^(.*\n?){0,3}(.*)$/
  const spaceNotAllowRegx = /^((?=\S).*\S\n)*(?=\S).*\S/

  function validateWeightVolumeNumber(value, name) {
    if (!!value) {
      console.log('validateWeightVolumeNumber : ' + value)
      return String(value).match(/^[0-9]{1,7}(?:\.[0-9]{1,3})?$/i);
    } else {
      return true
    }
  }

  function validateHSCode(value) {
    return /^[A-Za-z0-9]*$/i.test(value)
  }

  const numberErrorMsg = "Please enter only numeric values with 7 digits and 3 decimal points";

  const invalidPhoneMsg = "Invalid Phone Number"
  const invalidStrMsg = "`~$^+=|<> special characters are not allowed"
  const invalidAddressMsg = "Address exceed max lines (4 lines)"
  const invalidBlankSpaceMsg = "Blank space in beginning of field not allowed"
  return Yup.object({
    //Booking type
    shipmentType: Yup.string().required(requiredMessage).nullable(),

    //Schedule
    carrierPartyId: Yup.string().required(requiredMessage).nullable(),
    polCode: Yup.string().required(requiredMessage).nullable()
      .notOneOf(
        [Yup.ref('podCode')],
        'Port of Loading and Port of Discharge is required and cannot be the same.'
      ),
    podCode: Yup.string().required(requiredMessage).nullable()
      .notOneOf(
        [Yup.ref('polCode')],
        'Port of Loading and Port of Discharge is required and cannot be the same.'
      ),
    polEta: Yup.date()
      .required(requiredMessage)
      .validFormat()
      .nullable()
      .typeError(dateTypeErrorMessage)
      .min(moment().format('YYYY-MM-DD'), "pol Eta can't be before today."),
    // .when("polEtd", {
    //   is: null,
    //   then: Yup.date().max(Yup.ref('cargoDeliveryDate'), "pod eta can't be after cargo delivery date"),
    //   otherwise: Yup.date().max(Yup.ref('polEtd'), "pod eta can't be after pol etd"),
    // }),


    polEtd: Yup.date()
      .validFormat()
      .nullable()
      .typeError(dateTypeErrorMessage)
      .when(["cargoReadyDate", "polEta"], {
        is: (cargoReadyDate, polEta) => {
          return cargoReadyDate > polEta
        },
        then: Yup.date().min(Yup.ref('cargoReadyDate'), "pod eta can't be before cargo ready date"),
        otherwise: Yup.date().min(Yup.ref('polEta'), "pol etd can't be before pol eta")
      }),
    // .min(Yup.ref('cargoReadyDate'), "pol etd can't be before cargo ready date"),

    podEta: Yup.date()
      .validFormat()
      .nullable()
      .typeError(dateTypeErrorMessage)
      .when("polEtd", {
        is: null,
        then: Yup.date().min(Yup.ref('cargoReadyDate'), "pod eta can't be before cargo ready date"),
        otherwise: Yup.date().min(Yup.ref('polEtd'), "pod eta can't be before pol etd"),
      }),


    podEtd: Yup.date()
      .validFormat()
      .nullable()
      .typeError(dateTypeErrorMessage)
      .when("podEta", {
        is: null,
        then: Yup.date().when("polEtd", {
          is: null,
          then: Yup.date().min(Yup.ref('cargoReadyDate'), "pod etd can't be before cargo ready date"),
          otherwise: Yup.date().min(Yup.ref('polEtd'), "pod etd can't be before pol etd"),
        }),
        otherwise: Yup.date().min(Yup.ref('podEta'), "pod etd can't be before pod eta"),
      }),

    // .min(Yup.ref('cargoDeliveryDate'), "pod etd can't be before cargo delivery date"),


    //shipment
    moveType: Yup.string().required(requiredMessage).nullable(),
    placeOfReceiptCode: Yup.string().required(requiredMessage).nullable()
      .notOneOf(
        [Yup.ref('placeOfDeliveryCode')],
        'Place of Receipt and Place of Delivery is required and cannot be the same.'
      ),

    placeOfDeliveryCode: Yup.string().required(requiredMessage).nullable()
      .notOneOf(
        [Yup.ref('placeOfReceiptCode')],
        'Place of Receipt and Place of Delivery is required and cannot be the same.'
      ),


    placeOfReceipt: Yup.string().matches(excludeSpecialCharRegx, invalidStrMsg).nullable(),
    placeOfDelivery: Yup.string().matches(excludeSpecialCharRegx, invalidStrMsg).nullable(),
    destCode: Yup.string().matches(excludeSpecialCharRegx, invalidStrMsg).nullable(),
    finalDest: Yup.string().matches(excludeSpecialCharRegx, invalidStrMsg).nullable(),
    emptyPickupContactPhoneNo: Yup.string().matches(phoneRegx, invalidPhoneMsg).nullable(),
    shipFromContactPhoneNo: Yup.string().matches(phoneRegx, invalidPhoneMsg).nullable(),
    shipToContactPhoneNo: Yup.string().matches(phoneRegx, invalidPhoneMsg).nullable(),

    cargoReadyDate: Yup.date()
      .required(requiredMessage)
      .validFormat()
      .nullable()
      .typeError(dateTypeErrorMessage),
    //.min(moment().format('YYYY-MM-DD'), "cargo delivery date can't be before today."),
    //.max(Yup.ref('polEtd'), "cargo delivery date can't be after pol eta"),

    cargoDeliveryDate: Yup.date()
      .required(requiredMessage)
      .validFormat()
      .nullable()
      .typeError(dateTypeErrorMessage)
      .when("podEta", {
        is: null,
        then: Yup.date().min(Yup.ref('cargoReadyDate'), "cargo delivery date can't be before cargo ready date"),
        //otherwise: Yup.date().min(Yup.ref('podEta'), "cargo delivery date can't be before pod eta"),
      }),


    placeOfReceiptEtd: Yup.date()
      .validFormat()
      .nullable()
      .typeError(dateTypeErrorMessage)
      .min(moment().format('YYYY-MM-DD'), "place of receiptEtd can't be before today."),
    // placeOfDeliveryEta: null, //no validation for the two dates


    emptyPickupCompName: Yup.string().nullable().matches(spaceNotAllowRegx, invalidBlankSpaceMsg),

    emptyPickupCompAddress: Yup.string().when('emptyPickupCompName', {
      is: value => value && value.length > 0,
      then: Yup.string().required(requiredMessage).nullable()
        .matches(addressMaxRegx, invalidAddressMsg)
        .matches(spaceNotAllowRegx, invalidBlankSpaceMsg),
      otherwise: Yup.string().nullable()
    }),
    shipToCompName: Yup.string().when("showShipTo", {
      is: true,
      then: Yup.string().required(requiredMessage).nullable(),
      otherwise: Yup.string().nullable()
    }),
    shipToCompAddress: Yup.string().when("showShipTo", {
      is: true,
      then: Yup.string().required(requiredMessage).nullable(),
      otherwise: Yup.string().nullable()
    }),

    shipFromCompName: Yup.string().when("showShipFrom", {
      is: true,
      then: Yup.string().required(requiredMessage).nullable(),
      otherwise: Yup.string().nullable()
    }),
    shipFromCompAddress: Yup.string().when("showShipFrom", {
      is: true,
      then: Yup.string().required(requiredMessage).nullable(),
      otherwise: Yup.string().nullable()
    }),

    //container
    bookingContainers: Yup.array(
      Yup.object({
        containerQty: Yup.number().required(requiredMessage).moreThan(0, 'Invalid quantity').min(1, "Quantity cannot less than 1"),
        containerIsoType: Yup.string().required(requiredMessage).nullable(),
        containerStatus: Yup.string().required(requiredMessage).nullable()
      })
    ),

    //reefer details
    // reefer: Yup.object().shape({
    //   equipmentTemp: Yup.string().when("nonActiveReefer", {
    //     is: true,
    //     then: Yup.string().required(requiredMessage).nullable()
    //   }),
    //   equipmentControlledAtmosphereCO2: Yup.string().when(["nonActiveReefer", "equipmentControlledAtmosphere"], {
    //     is: true,
    //     then: Yup.string().required(requiredMessage).nullable()
    //   }),
    //   equipmentControlledAtmosphereN2: Yup.string().when(["nonActiveReefer", "equipmentControlledAtmosphere"], {
    //     is: true,
    //     then: Yup.string().required(requiredMessage).nullable()
    //   }),
    //   equipmentControlledAtmosphereO2: Yup.string().when(["nonActiveReefer", "equipmentControlledAtmosphere"], {
    //     is: true,
    //     then: Yup.string().required(requiredMessage).nullable()
    //   }),
    //   equipmentAirFlow: Yup.string().when(["nonActiveReefer", "ventSetting"], {
    //     is: true,
    //     then: Yup.string().required(requiredMessage).nullable()
    //   }),
    //   equipmentHumidity: Yup.string().when(["nonActiveReefer", "humidty"], {
    //     is: true,
    //     then: Yup.string().required(requiredMessage).nullable()
    //   })
    // }),

    //Cargo
    bookingCargoes: Yup.array().when("saveDraft", {
      is: false,
      then: Yup.array(
        Yup.object({
          quantity: Yup.number().integer("Integer value only").min(0, "Value too small").max(99999, "Value too large").nullable(),
          cargoDesc: Yup.string().required(requiredMessage).nullable(),
          hsCode: Yup.string().test("hsCode", "Please enter a valid HS code.", value => validateHSCode(value)).nullable(),
          grossVolume: Yup.mixed().test("grossVolume", numberErrorMsg, value => validateWeightVolumeNumber(value)),
          grossWeight: Yup.mixed().test("grossWeight", numberErrorMsg, value => validateWeightVolumeNumber(value)),
          netVolume: Yup.mixed().test("netVolume", numberErrorMsg, value => validateWeightVolumeNumber(value)),
          netWeight: Yup.mixed().test("netWeight", numberErrorMsg, value => validateWeightVolumeNumber(value)),
          totalVgmWeight: Yup.mixed().test("totalVgmWeight", numberErrorMsg, value => validateWeightVolumeNumber(value)),
        })
      )
    }),

    //Contact Details
    representativeEmail: Yup.string().email(invalidEmailMessage).nullable(),
    representativeContactNumber: Yup.string().matches(phoneRegx, invalidPhoneMsg).nullable(),
    representativeName: Yup.string().matches(excludeSpecialCharRegx, invalidStrMsg).nullable(),

  })
}

export default makeValidationSchema
