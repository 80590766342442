import React, { useRef, useState, useEffect } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useParams, useLocation } from 'react-router-dom'

import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import FreightBookingTranslationText from '../../shared/FreightBookingTranslationText'
import MyBookingAmendFormProperties from './MyBookingAmendFormProperties'
import pathMap from 'src/paths/PathMap_FreightBooking'
import Api from '../../shared/api'
import Utils from '../../shared/Utils'
import useAmendBookingMakeValidationSchema from './useAmendBookingMakeValidationSchema'

const {
  form: { CngViewForm },
  table: { useDefaultNotification }
} = components

function MyBookingAmendPage({ showNotification }) {
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const { fetchRecords } = useServices()
  //const { createRecord } = useServices()
  const [loading, setLoading] = useState(false)
  const translatedTextsObject = FreightBookingTranslationText()
  const [booking, setBooking] = useState([])
  const validationSchema = useAmendBookingMakeValidationSchema()
  const bookingId = useRef()
  const freightBookingId = useRef()
  const historyData = location.state

  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)


  function onSubmit(data) {

    console.log(data)
    history.push({
      pathname: pathMap.MY_BOOKING_AMEND_REVIEW,
      state: data
    })


  }

  const bookingRequest = {
    bookingId: location.state == null ? 0 : location.state.bookingId,
    dockey: location.state == null ? "" : location.state.dockey
  }

  function populateBookingData(data) {
    console.log(data)
    setBooking(data)

  }

  useEffect(() => {
    console.log(historyData)
    if (historyData != null && historyData.page == Utils.Page.BookingList) {
      console.log('start get booking')
      Api.fetchBookingDetailsById(fetchRecords, bookingRequest, populateBookingData)
    } else {
      console.log("get data from history")
      setBooking(historyData)
    }
  }, [])


  return (

    // use CngViewForm here for customizable button set
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <MyBookingAmendFormProperties.FormFields
          disabled={false}
          showNotification={showNotification}
          loading={loading}
          booking={booking}
        />
      }
      formikProps={{
        ...MyBookingAmendFormProperties.formikProps,
        validationSchema,
        onSubmit: onSubmit
      }}
    // toClientDataFormat={PollingFormProperties.toClientDataFormat}
    // toServerDataFormat={PollingFormProperties.toServerDataFormat}
    />

  )
}

export default MyBookingAmendPage
