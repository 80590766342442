import { Yup, constants } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import moment from 'moment'
import { useTranslation } from 'cng-web-lib'


const {
    locale: {
        key: { CommonValidationMessageKeys }
    }
} = constants


// export const numberErrorMsg = "Please enter only numeric values with 7 digits and 3 decimal points";

// export const invalidPhoneMsg = "Invalid Phone Number"
// export const invalidStrMsg = "`~$^+=|<> special characters are not allowed"
// export const invalidAddressMsg = "Address exceed max lines (4 lines)"
// export const invalidBlankSpaceMsg = "Blank space in beginning of field not allowed"
// export const invalidHSCode = "Please enter a valid HS code."
// export const valueTooSmallMsg = "value too small"
// export const valueTooBigMsg = "value too large"
// export const IntegerOnlyMsg = "Integer value only"


export const phoneRegx = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/
export const excludeSpecialCharRegx = /^[^~$^+=|<>]*$/
export const excludeSpecialCharRegx2 = /^[^<+>^`~$^=|]*$/
export const addressMaxRegx = /^(.*\n?){0,3}(.*)$/
export const spaceNotAllowRegx = /^((?=\S).*\S\n)*(?=\S).*\S/
export const HSCodeRegx = /^[A-Za-z0-9]*$/
export const WeightVolumeRegx = /^[0-9]{1,7}(?:\.[0-9]{1,3})?$/
export const dgWeightVolumeRegx = /^[0-9]{1,6}(?:\.[0-9]{1,3})?$/

export const undgRegex = /^([0-9]{4})?$/
export const emailListRegx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}) *)(, *(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}) *))*,? *$/

