import React, { useEffect, useRef, useState } from 'react'
import { constants, useServices } from 'cng-web-lib'

import Api from './api'

const VALUE_ALL = "ALL"
export default class FreightBookingApiParser {
  static parseSortingType(data) {
    let tempList = []
                data.content.map(v => {
                    let temp = {
                        text: v.sortDesc,
                        value: v.sortCode
                    }
                    tempList.push(temp)
                })
    return  tempList        
  }

    
  /**
   * parses the all the data into an array for all the statuscodes so that all bookings will be viewed on one page
   * @param {*} data 
   * @returns {... data, fullList}
   */
  static parseHelpdeskStatusCodeAndCount(data) {
    let concatList = data.pendingStatusList.concat(data.processedStatusList)
    let fullList = concatList.map(
        (v) => ({...v, checked: true, eligible: true})
    )
    if (fullList[0] !== undefined) {
      let newRecord = {...fullList[0]}
      newRecord.statusDesc = VALUE_ALL
      fullList = [...fullList, newRecord]
      console.log(fullList)
    }
    return { ...data, fullList }
  }



  static parseStatusCodeAndCount(data) {
    console.log(data)
    let pendingList = data.pendingStatusList.map((v) => ({ ...v, checked: true, eligible: true }))
    if (pendingList[0] !== undefined) {
        let newRecord = { ...pendingList[0] }
        newRecord.statusDesc = VALUE_ALL

        pendingList = [...pendingList, newRecord]

        console.log(pendingList)
    }

    let processedList = data.processedStatusList.map((v) => ({ ...v, checked: true, eligible: true }))
    if (processedList[0] !== undefined) {
        let newRecord = { ...processedList[0] }
        newRecord.statusDesc = VALUE_ALL

        processedList = [...processedList, newRecord]

        console.log(processedList)
    }
    return {...data, pendingList, processedList}
  }

  static parsePartyId(data) {
     return data.content.map((v) => ({ ...v, checked: true, eligible: true }))
  }
}