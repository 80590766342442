import { Yup } from 'cng-web-lib'
import * as ValidationRegex from './ValidationRegex'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function DGCargoForEmergencyValidationSchema() {


  const validationMessageTranslation = ValidationMessageTranslationText()

  return Yup.object({

    emergencyContactNo: Yup.string().required(validationMessageTranslation.requiredMessage).matches(ValidationRegex.phoneRegx, validationMessageTranslation.invalidPhoneMsg).nullable(),
    emergencyContact: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),


  })
}

export default DGCargoForEmergencyValidationSchema

