import CngAdminApiUrls from 'src/apiUrls/CngAdminApiUrls.js'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls.js'

function fetchBookingDetailsById(fetchRecords, bookingRequest, onSuccess) {
  console.log('getBookingDetailsById...')
  fetchRecords.execute(
    FreightBookingApiUrls.BOOKING_GET_BY_ID,
    { customData: bookingRequest },
    (data) => {
      onSuccess(data)
    },
    (error) => {
      console.log(error)
    }
  )
}

/**
 * Fetches bookings by ID for helpdesk page
 * @param {*} fetchRecords
 * @param {*} bookingRequest
 * @param {*} onSuccess
 */
function fetchHelpdeskBookingDetailsById(
  fetchRecords,
  bookingRequest,
  onSuccess
) {
  console.log('getHelpdeskBookingDetailsById...')
  fetchRecords.execute(
    FreightBookingApiUrls.HELPDESK_BOOKING_GET_BY_ID,
    { customData: bookingRequest },
    (data) => {
      onSuccess(data)
    },
    (error) => {
      console.log(error)
    }
  )
}

function fetchBookingList(fetchRecords, searchCriteria, onSuccess, onError) {
  console.log('getBookingList')
  console.log(searchCriteria)
  fetchRecords.execute(
    FreightBookingApiUrls.BOOKING_LIST,
    {
      customData: searchCriteria
    },
    (data) => {
      console.log('booking is back')
      console.log('data: ' + data)
      onSuccess(data)
    },
    (error) => {
      onError(error)
    }
  )
}

function fetchBookingListEnhanced(
  fetchRecords,
  searchCriteria,
  onSuccess,
  onError
) {
  console.log('fetchBookingListEnhanced')
  console.log(searchCriteria)
  fetchRecords.execute(
    FreightBookingApiUrls.BOOKING_LIST_ENHANCEMENT,
    {
      customData: searchCriteria
    },
    (data) => {
      onSuccess(data)
    },
    (error) => {
      onError(error)
    }
  )
}

/**
 * Fetches all bookings for helppdesk page
 * @param {*} fetchRecords
 * @param {*} searchCriteria
 * @param {*} onSuccess
 */
function fetchHelpdeskBookingList(fetchRecords, searchCriteria, onSuccess) {
  console.log('getHelpdeskBookingList')
  console.log(searchCriteria)
  fetchRecords.execute(
    FreightBookingApiUrls.HELPDESK_BOOKING_LIST,
    {
      customData: searchCriteria
    },
    (data) => {
      onSuccess(data)
    },
    (error) => {
      console.log(error)
    }
  )
}

function fetchSortingType(fetchRecords, onSuccess, onError) {
  fetchRecords.execute(
    FreightBookingApiUrls.SORTING_TYPE,
    {},
    (data) => {
      console.log('sort type is back')
      console.log(data)
      onSuccess(data)
    },
    (error) => {
      onError(error)
    }
  )
}

function fetchStatusCodeAndCount(fetchRecords, onSuccess, onError) {
  fetchRecords.execute(
    FreightBookingApiUrls.BOOKING_STATUS_LIST,
    {},
    (data) => {
      console.log('status is back ' + data.pendingStatusList.length)
      console.log(data)
      onSuccess(data)
    },
    (error) => {
      onError(error)
    }
  )
}

/**
 * Fetches the status codes for of the bookings for the helpdesk and also
 * fetches fetches the count for the number of bookings fetched
 * @param {*} fetchRecords
 * @param {*} onSuccess
 */
function fetchHelpdeskStatusCodeAndCount(fetchRecords, onSuccess) {
  fetchRecords.execute(
    FreightBookingApiUrls.HELPDESK_BOOKING_STATUS_LIST,
    {},
    (data) => {
      onSuccess(data)
    },
    (error) => {
     console.log(error)
    }
  )
}

function fetchPartyId(fetchRecords, searchCriteria, onSuccess, onError) {
  console.log('fetchPartyIdFromAPI')
  console.log(searchCriteria)
  fetchRecords.execute(
    FreightBookingApiUrls.PARTY_LIST_BOOKING_COUNT,
    {
      customData: searchCriteria
    },
    (data) => {
      console.log('party is back')
      console.log(data)
      onSuccess(data)
    },
    (error) => {
      onError(error)
    }
  )
}

/**
 * Fetches partyID for helpdesk
 * @param {*} fetchRecords
 * @param {*} searchCriteria
 * @param {*} onSuccess
 */
function fetchHelpdeskPartyId(fetchRecords, searchCriteria, onSuccess) {
  console.log('fetchHelpdeskPartyIdFromAPI')
  console.log(searchCriteria)
  fetchRecords.execute(
    FreightBookingApiUrls.HELPDESK_PARTY_LIST_BOOKING_COUNT,
    {
      customData: searchCriteria
    },
    (data) => {
      onSuccess(data)
    },
    (error) => {
      console.log(error)
    }
  )
}
function fetchAuditTrailListing(fetchRecords, keyReferenceNo, onSuccess) {
  console.log('fetchAuditTrailListing 1:', keyReferenceNo)
  console.log(keyReferenceNo)
  fetchRecords.execute(
    CngAdminApiUrls.AUDIT_TRAIL_LISTING,
    {
      filters: [
        {
          field: 'auditModuleCode',
          operator: 'EQUAL',
          value: 'CLOGS_FRB'
        },
        {
          field: 'keyReferenceNo',
          operator: 'EQUAL',
          value: keyReferenceNo
        }
      ]
    },
    (data) => {
      onSuccess(data.content)
    },
    (error) => {
      console.log(error)
    }
    
  )
}
function fetchAuditTrailCompare(fetchRecords, versions, onSuccess) {
  fetchRecords.execute(
    CngAdminApiUrls.AUDIT_TRAIL_COMPARE,
    {
      customData: versions
    },
    (data) => {
      console.log(data)
      onSuccess(data)
    },
    (error) => {
      console.log(error)
    }
  )
}
function fetchParties(fetchRecords, onSuccess) {
  console.log('fetchAuditTrailCompare')

  fetchRecords.execute(
    `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
    {},
    (data) => {
      console.log('fetchParties back')
      console.log(data)
      onSuccess(data)
    },
    (error) => {
      console.log(error)
    }
  )
}
function fetchUsersWithCriteria(fetchRecords, userCriteria, onSuccess) {
  console.log('fetchAuditTrailCompare')

  fetchRecords.execute(
    `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/detail/get`,
    {
      customData: {},
      filters: [
        {
          field: 'id',
          operator: 'IN',
          value: userCriteria
        }
      ]
    },
    (data) => {
      console.log('fetchUsers back')
      console.log(data)
      onSuccess(data)
    },
    (error) => {
      console.log(error)
    }
  )
}
const FreightBookingApi = Object.freeze({
  fetchBookingDetailsById,
  fetchHelpdeskBookingDetailsById,
  fetchBookingList,
  fetchBookingListEnhanced,
  fetchHelpdeskBookingList,
  fetchSortingType,
  fetchStatusCodeAndCount,
  fetchHelpdeskStatusCodeAndCount,
  fetchPartyId,
  fetchHelpdeskPartyId,
  fetchAuditTrailListing,
  fetchAuditTrailCompare,
  fetchParties,
  fetchUsersWithCriteria
})

export default FreightBookingApi
