import { Yup } from 'cng-web-lib'
import * as ValidationRegex from './ValidationRegex'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function ContactValidationSchema() {

  const validationMessageTranslation = ValidationMessageTranslationText()

  function validateEmailList(value) {
    if (value == null || value.length < 1) {
      return true
    }

    return value.every(element => ValidationRegex.emailListRegx.test(element))
  }

  return Yup.object({

    representativeEmail: Yup.string().email(validationMessageTranslation.invalidEmailMessage).nullable(),
    representativeContactNo: Yup.string().matches(ValidationRegex.phoneRegx, validationMessageTranslation.invalidPhoneMsg).nullable(),
    representativeName: Yup.string().matches(ValidationRegex.excludeSpecialCharRegx, validationMessageTranslation.invalidStrMsg).nullable(),
    partnerEmails: Yup.array().max(6, validationMessageTranslation.exceedEmailListLengthMsg)
    .test(
      'partnerEmails',
      validationMessageTranslation.invalidEmailListMsg,
      (value) => validateEmailList(value)
    )
    .nullable()

  })
}

export default ContactValidationSchema

