import {   constants, useServices } from 'cng-web-lib'
import React, { useEffect, useState } from 'react'
import {  useLocation } from 'react-router-dom'

import Api from '../../shared/api'

import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import MyBookingReviewApproved from './MyBookingReviewApproved'
import ViewBookingTypeComponent from '../../components/ViewBookingTypeComponent'
import ViewScheduleComponent from '../../components/ViewScheduleComponent'
import makeValidationSchema from '../../shared/MakeValidationSchema'

const {
  CodeMaintenanceType
} = constants


const DEFAULT_INITIAL_VALUES = Object.freeze({
  ...ViewBookingTypeComponent.initialValues,
  ...ViewScheduleComponent.initialValues,
})
const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function FormFields(props) {
  const location = useLocation()
  const { fetchRecords } = useServices()
  const [auditList, setAuditList] = useState([])
  const [userCriteria, setUserCriteria] = useState([])

  const [booking, setBooking] = useState({})
  const [ports, setPorts] = useState([])
  const bookingRequest = {
    bookingId: location.state.bookingId,
    dockey: location.state.dockey
  }

  const helpdesk = location.state.hasOwnProperty('helpdesk') ? location.state.helpdesk : false;

  useEffect(() => {
    (helpdesk ? 
      Api.fetchHelpdeskBookingDetailsById(fetchRecords, bookingRequest, populateBookingData)
      :
      Api.fetchBookingDetailsById(fetchRecords, bookingRequest, populateBookingData)
      )
    CodeMaintainanceApi.fetchCodeMaintainance(fetchRecords, CodeMaintenanceType.PORT,
      [], false, onLoadPortSuccess)
    
  }, [])
  

  function populateBookingData(data) {
    data.bookStatusDesc = location.state.bookStatusDesc
    setBooking(data)
    if(data.freightBookingId) {
      Api.fetchAuditTrailListing(fetchRecords, data.freightBookingId, onAuditTrailListingLoadSuccess)
    }
  }
  function onAuditTrailListingLoadSuccess(data) {
    data.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
    setAuditList(data)
    //get the user id in the list
    let userList = []
    data.forEach((val)=>{
      userList.push(val.userId)
    })
    setUserCriteria(userList)
  }


  function onLoadPortSuccess(data) {
      setPorts(data)
  }

  const [menuCodeList, setMenuCodeList] = useState([])
    useEffect(() => {
      console.log("get privileges")

    fetchRecords.execute(
      `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user-role/menu-privileges/get`,
      undefined,
      (data) => {
        let temp = []
        data.moduleList.map(item => {
          item.menuPrivilegeList.map(inner_item => {
            if (!temp.includes(inner_item.menuCode)) {
              temp.push(inner_item.menuCode)
            }
          })
        })
        setMenuCodeList(temp)
        //console.log("menuList: " + temp)
      },
      (error) => {
        console.log(error)
      }
    )
  }, [])

  return (
    <div>
      <MyBookingReviewApproved.FormFields booking={booking} ports={ports} auditList={auditList} userCriteria={userCriteria} {...props} helpdesk={helpdesk} menuCodeList={menuCodeList} />
    </div>
  )
}

const BodySection = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
})


export default BodySection
