import { BasePage as CngBasePage } from 'cng-web-lib'
import MyBookingReviewDraftPage from '../mybooking/review/MyBookingReviewDraftPage'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_FreightBooking'
import TranslationText from '../shared/FreightBookingTranslationText'
import ViewOnlyBookingPage from './ViewOnlyBookingPage.js'

function WrappedViewOnlyBookingPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = TranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <ViewOnlyBookingPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedViewOnlyBookingReviewPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = TranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <MyBookingReviewDraftPage
          showNotification={showSnackbar}
          isViewBk={true}
          {...props}
        />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
  WrappedViewOnlyBookingPage as ViewOnlyBookingPage,
  WrappedViewOnlyBookingReviewPage as ViewOnlyBookingReviewPage
}
