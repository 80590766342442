import { BasePage as CngBasePage } from 'cng-web-lib'
import CreateNewBookingPage from './CreateNewBookingPage.js'
import React from 'react'
import ReviewBookingPage from './review/ReviewBookingPage.js'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_FreightBooking'
import TranslationText from '../shared/FreightBookingTranslationText'

function NewBookingPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = TranslationText()

  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <CreateNewBookingPage showNotification={showSnackbar} {...props} />
        // <NewBookingForm />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedReviewBookingPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = TranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <ReviewBookingPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedBookingSubmittedPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = TranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <ReviewBookingPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
  NewBookingPage,
  WrappedReviewBookingPage as ReviewBookingPage,
  WrappedBookingSubmittedPage as BookingSubmittedPage
}
