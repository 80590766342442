import { Yup } from 'cng-web-lib'
import moment from 'moment'
import CommonUtils from 'src/views/common/utils/Utils'
import * as ValidationRegex from './ValidationRegex'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function ScheduleValidationSchema() {

  const validationMessageTranslation = ValidationMessageTranslationText()

  return Yup.object({

    carrierPartyId: Yup.string().nullable()
      .when("saveDraft", {
        is: false,
        then: Yup.string().required(validationMessageTranslation.requiredMessage),
      }),
    //.required(validationMessageTranslation.requiredMessage).nullable(),
    polCode: Yup.string().nullable()
      .when("saveDraft", {
        is: false,
        then: Yup.string().required(validationMessageTranslation.requiredMessage)
          .notOneOf(
            [Yup.ref('podCode')],
            validationMessageTranslation.PolPodSameMsg
          ),
      })
      .test("polCode", validationMessageTranslation.PolPodSameMsg, function (
        val
      ) {
        const { podCode } = this.parent;
        if (podCode && podCode == val) return val == null
        return true
      }),

    podCode: Yup.string().nullable()
      .when("saveDraft", {
        is: false,
        then: Yup.string().required(validationMessageTranslation.requiredMessage)
          .notOneOf(
            [Yup.ref('polCode')],
            validationMessageTranslation.PolPodSameMsg
          ),
      })
      .when('polCode', {
        is: value => value && value.length > 0,
        then: Yup.string().nullable()
          .notOneOf(
            [Yup.ref('polCode')],
            validationMessageTranslation.PolPodSameMsg
          ),
      }),

    //pol eta is required all the conditions
    polEta: Yup.date()
      .when("amendBooking", {
        is: true,
        then: Yup.date()
          .required(validationMessageTranslation.requiredMessage)
          // .validFormat(CommonUtils.UI_FORMAT_DATE_TIME)
          .nullable()
          .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg),

        otherwise: Yup.date()
          .required(validationMessageTranslation.requiredMessage)
          // .validFormat(CommonUtils.UI_FORMAT_DATE_TIME)
          .nullable()
          .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
          .min(moment(moment().format(CommonUtils.UI_FORMAT_DATE)).format(CommonUtils.UI_FORMAT_DATE_TIME), validationMessageTranslation.POLETABeforeTodayMsg),

      }),



    polEtd: Yup.date()
      .nullable()
      .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
      .when("amendBooking", {
        is: true,
        then: Yup.date()
          .nullable()
          .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
          .required(validationMessageTranslation.requiredMessage)
          .min(Yup.ref('polEta'), validationMessageTranslation.POLETDBeforePOLETAMsg),
          // .validFormat(CommonUtils.UI_FORMAT_DATE_TIME)
          // .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
          // .when(["cargoReadyDate", "polEta"], {
          //   is: (cargoReadyDate, polEta) => {
          //     return false //cargoReadyDate > polEta
          //   },
          //   then: Yup.date()
          //     .nullable()
          //     .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg),
          //   //.min(Yup.ref('cargoReadyDate'), validationMessageTranslation.POLETDBeforeCargoReadyDateMsg),
          //   otherwise: Yup.date()
          //     .nullable()
          //     .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
          //     .min(Yup.ref('polEta'), validationMessageTranslation.POLETDBeforePOLETAMsg)
          // }),
        otherwise: Yup.date()
          .nullable()
          .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
          .min(Yup.ref('polEta'), validationMessageTranslation.POLETDBeforePOLETAMsg),
          // .when(["cargoReadyDate", "polEta"], {
          //   is: (cargoReadyDate, polEta) => {
          //     return cargoReadyDate > polEta
          //   },
          //   then: Yup.date()
          //     .nullable()
          //     .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg),
          //   //.min(Yup.ref('cargoReadyDate'), validationMessageTranslation.POLETDBeforeCargoReadyDateMsg),
          //   otherwise: Yup.date()
          //     .nullable()
          //     .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
          //     .min(Yup.ref('polEta'), validationMessageTranslation.POLETDBeforePOLETAMsg)
          // }),
      }),


    podEta: Yup.date()
      .nullable()
      .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
      .when("amendBooking", {
        is: true,
        then: Yup.date()
          // .validFormat(CommonUtils.UI_FORMAT_DATE_TIME)
          .nullable()
          .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
          .required(validationMessageTranslation.requiredMessage)
          .when("polEtd", {
            is: null,
            then: Yup.date()
              .nullable()
              .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
              .min(Yup.ref('cargoReadyDate'), validationMessageTranslation.PODETABeforeCargoReadyDateMsg),
            otherwise: Yup.date()
              .nullable()
              .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
              .min(Yup.ref('polEtd'), validationMessageTranslation.PODETABeforePOLETDMsg),
          }),
         // .max(Yup.ref('cargoDeliveryDate'), validationMessageTranslation.PODETAAfterCargoDeliveryDateMsg),

        otherwise: Yup.date()
          // .validFormat(CommonUtils.UI_FORMAT_DATE_TIME)
          .nullable()
          .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
          .when("polEtd", {
            is: null,
            then: Yup.date()
              .nullable()
              .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
              .min(Yup.ref('cargoReadyDate'), validationMessageTranslation.PODETABeforeCargoReadyDateMsg),
            otherwise: Yup.date()
              .nullable()
              .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
              .min(Yup.ref('polEtd'), validationMessageTranslation.PODETABeforePOLETDMsg),
          }),
      }),


    podEtd: Yup.date()
      // .validFormat(CommonUtils.UI_FORMAT_DATE_TIME)
      .nullable()
      .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
      .when("amendBooking", {
        is: true,
        then: Yup.date()
          .nullable()
          .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
          .required(validationMessageTranslation.requiredMessage),
        otherwise: Yup.date()
          .nullable()
          .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
      })
      .when("podEta", {
        is: null,
        then: Yup.date().when("polEtd", {
          is: null,
          then: Yup.date()
            .nullable()
            .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
            .min(Yup.ref('cargoReadyDate'), validationMessageTranslation.PODETDBeforeCargoReadyDateMsg),
          otherwise: Yup.date()
            .nullable()
            .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
            .min(Yup.ref('polEtd'), validationMessageTranslation.PODETDBeforePOLETDMsg),
        }),
        otherwise: Yup.date()
          .nullable()
          .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
          .min(Yup.ref('podEta'), validationMessageTranslation.PODETDBeforePODETAMsg),
      }),


    //vessel/voyage for carrier amend booking
    vesselName: Yup.string().when("amendBooking", {
      is: true,
      then: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),
      otherwise: Yup.string().nullable()
    }),

    voyageNo: Yup.string().when("amendBooking", {
      is: true,
      then: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),
      otherwise: Yup.string().nullable()
    }),



  })
}

export default ScheduleValidationSchema

