import { Yup } from 'cng-web-lib'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function ContainerValidationSchema() {

  const validationMessageTranslation = ValidationMessageTranslationText()
  return Yup.object({

    //container
    bookingContainers: Yup.array(
      Yup.object({
        containerQty: Yup.number()
          .integer(validationMessageTranslation.IntegerOnlyMsg)
          .required(validationMessageTranslation.requiredMessage)
          .nullable()
          .min(1, validationMessageTranslation.valueTooSmallMsg).max(99999, validationMessageTranslation.valueTooBigMsg),
        containerIsoType: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),
        containerStatus: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),

        //Reefer validation
        equipmentTemp: Yup.number().when(["nonActiveReefer", "reeferAvailable"], {
          is: (nonActiveReefer, reeferAvailable) => !nonActiveReefer && reeferAvailable,
          then: Yup
            .number()
            .transform((value) => Number.isNaN(value) ? null : value)
            .required(validationMessageTranslation.requiredMessage)
            .nullable(),
          otherwise: Yup
            .number()
            .optional()
            .transform(value => (isNaN(value) ? undefined : value))
            .nullable(true)
        })
      })
    ),
  })
}

export default ContainerValidationSchema


