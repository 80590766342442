import { BasePage as CngBasePage } from 'cng-web-lib'
import MyBookingAmendPage from './amend/MyBookingAmendPage'
import MyBookingAmendReviewPage from './amend/MyBookingAmendReviewPage'
import MyBookingPage from '../mybooking/MyBookingPage.js'
import MyBookingReviewDraftPage from './review/MyBookingReviewDraftPage'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_FreightBooking'
import TranslationText from '../shared/FreightBookingTranslationText'

function WrappedMyBookingPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = TranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <MyBookingPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedMyBookingReviewDraftPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = TranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <MyBookingReviewDraftPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedMyBookingAmendPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = TranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <MyBookingAmendPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedMyBookingAmendReviewPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = TranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <MyBookingAmendReviewPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedMyBookingAmendSubmittedPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = TranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <MyBookingAmendReviewPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
  WrappedMyBookingPage as MyBookingPage,
  WrappedMyBookingReviewDraftPage as MyBookingReviewDraftPage,
  WrappedMyBookingAmendPage as MyBookingAmendPage,
  WrappedMyBookingAmendReviewPage as MyBookingAmendReviewPage,
  WrappedMyBookingAmendSubmittedPage as MyBookingAmendSubmittedPage
}
