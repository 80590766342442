import { Box, Paper } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useLocation } from 'react-router-dom'

import AmendBookingButtonComponent from '../../components/AmendBookingButtonComponent'
import AmendBookingTypeComponent from '../../components/AmendBookingTypeComponent'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CargoComponent from '../../components/CargoComponent'
import CngBackdrop from '../../../vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import WarningDialog from 'src/components/dialog/WarningDialog'
import ContactDetailsComponent from '../../components/ContactDetailsComponent'
import ContainerComponent from '../../components/ContainerComponent'
import ReeferComponent from '../../components/ReeferComponent'
import DocumentComponent from '../../components/DocumentComponent'
import FreightBookingTranslationText from '../../shared/FreightBookingTranslationText'
import GeneralStepperComponent from 'src/views/common/ui/GeneralStepperComponent'
import PaymentInstructionComponent from '../../components/PaymentInstructionComponent'
import ScheduleComponent from '../../components/ScheduleComponent'
import ShipmentComponent from '../../components/ShipmentComponent'
import { v4 as uuid } from 'uuid'
import pathMap from 'src/paths/PathMap_FreightBooking'
import ViewCargoComponent from '../../components/ViewCargoComponent'
import ViewPaymentComponent from '../../components/ViewPaymentComponent'
import ViewScheduleComponent from '../../components/ViewScheduleComponent'
import Utils from 'src/views/common/utils/Utils'
import Api from '../../shared/api'
import FrbUtils from '../../shared/Utils'
import QuickScroll from 'src/components/iconbutton/QuickScroll'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  saveDraft: false,
  amendBooking: true,
  bookingId: '',
  setFreightBookingId: '',
  bookingStatus: '',
  ...AmendBookingTypeComponent.initialValues,
  ...ScheduleComponent.initialValues,
  ...ShipmentComponent.initialValues,
  ...ContainerComponent.initialValues,
  ...ReeferComponent.initialValues,
  ...ContactDetailsComponent.initialValues,
  ...PaymentInstructionComponent.initialValues,
  ...CargoComponent.initialValues
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function FormFields({ disabled, showNotification, loading }) {
  const { errors } = useFormikContext()
  const history = useHistory()
  const location = useLocation()
  const data = location.state

  const fbTranslatedTextsObject = FreightBookingTranslationText()
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [warningDialogOpen, setWarningDialogOpen] = useState(false)

  const [ammendConfirmDialogOpen, setAmmendConfirmDialogOpen] = useState(false)
  const [contentMessage, setContentMessage] = useState()
  //move this from BookingTypeComponent to share with Cargo component
  // const [hazardousField, , { setValue: setHazardousField }] = useField('hazardous')

  //move this from ShipmentComponent to handle onChange in ScheduleComponent
  const [placeOfReceiptCodeField, ,] = useField('placeOfReceiptCode')
  const [placeOfDeliveryCodeField, ,] = useField('placeOfDeliveryCode')
  const [, , { setValue: setPlaceOfReceiptField }] = useField('placeOfReceipt')
  const [, , { setValue: setPlaceOfDeliveryField }] =
    useField('placeOfDelivery')

  const placeOfReceiptKeyRef = useRef(uuid())
  const placeOfDeliveryKeyRef = useRef(uuid())
  const isEdit = useRef(false)

  const { fetchRecords } = useServices()
  const { submitForm, setFieldValue } = useFormikContext()
  const [shouldRender, setShouldRender] = useState(false)
  const [booking, setBooking] = useState({})
  const [forceExpand, setForceExpand] = useState(false)
  const amendBookingSteps = [
    fbTranslatedTextsObject.stepLabelAmendBooking,
    fbTranslatedTextsObject.stepLabelReviewAmendments,
    fbTranslatedTextsObject.stepLabelAmendmentsSubmitted
  ]

  function isEmpty(obj) {
    return Object.keys(obj).length === 0
  }

  const submitAmendments = () => {
    if (!isEmpty(errors)) {
      setWarningDialogOpen(true)

      //expand the shippment section
      setForceExpand(true)
    }else{
      const placeOfReceiptEtd = new Date(data?.booking?.placeOfReceiptEtd)
      const placeOfDeliveryEta = new Date(data?.booking?.placeOfDeliveryEta)
      
      const today = new Date()

      if(placeOfReceiptEtd < today && placeOfDeliveryEta < today){
        
        setContentMessage(fbTranslatedTextsObject.PoRETDAndPoDETAWarningMessage)
        setAmmendConfirmDialogOpen(true)
      }
      else if(placeOfReceiptEtd < today){
        
        setContentMessage(fbTranslatedTextsObject.PoRETDWarningMessage)
        setAmmendConfirmDialogOpen(true)
      }
      else if(placeOfDeliveryEta < today){
        
        setContentMessage(fbTranslatedTextsObject.PoDETAWarningMessage)
        setAmmendConfirmDialogOpen(true)
      }
      else{
        proceedAmendments()
      }
    }
  }

  const proceedAmendments = async () => {
    await setFieldValue('amendBooking', true) //use this to bypass the validation check
    submitForm()
  }

  const cancelBooking = () => {
    history.push({
      pathname: pathMap.MY_BOOKING
    })
  }

  useEffect(() => {
    if (data == null) return
    if (
      data.action === FrbUtils.Action.amend &&
      data.prevPage === FrbUtils.Page.BookingList
    ) {
      const bookingRequest = {
        bookingId: data.booking ? data.booking.bookingId : '',
        dockey: data.booking ? data.booking.dockey : ''
      }
      Api.fetchBookingDetailsById(
        fetchRecords,
        bookingRequest,
        populateBookingData
      )
    } else if (data.booking !== null) {
      setBooking(data.booking)
    }
  }, [])

  function populateBookingData(bookingData) {
    bookingData.bookStatusDesc = location.state.bookStatusDesc
    setBooking(bookingData)
  }

  useEffect(() => {
    if (booking != null && !isEmpty(booking)) {
      placeOfReceiptKeyRef.current = uuid()
      placeOfDeliveryKeyRef.current = uuid()
      // FetchPortCountryList()
      PopulateDataFromReview()
      setShouldRender(true)
    }
  }, [booking])

  function PopulateDataFromReview() {
    console.log("populateDataFromReview(): data : ", data)
    console.log("populateDataFromReview(): booking : ", booking)
    if (booking != null && !isEmpty(booking)) {
      console.log(
        'populate data representativeName ' + booking.representativeName
      )
      const dataArr = Object.entries(booking)
      dataArr.forEach(([key, val]) => {
        if (FrbUtils.dateTimeFields.includes(key)) {
          val = Utils.formatString(val, Utils.UI_FORMAT_DATE_TIME)
        } else if (FrbUtils.dateFields.includes(key)) {
          val = Utils.formatString(val, Utils.UI_FORMAT_DATE)
        }

        //as we are using useArrayField for cargo/dg cargo, the setFieldValue cannot be null, hence need to convert null to [] for them
        if (FrbUtils.nonNullableArrayFields.includes(key)) {
          val = val === null ? [] : val
        }

        setFieldValue(key, val)
      })

      if (booking.bookingCargoes == null) {
        booking.bookingCargoes = []
      }
      isEdit.current = true
    }
  }

  if (!shouldRender) {
    return null
  }

  return (
    <Box id="top-level-box">
      <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
        <CngBackdrop loading={loading} />
      </CngGridItem>

      <Paper>
        <Box alignItems='center' pr={15} pl={15}>
          <GeneralStepperComponent steps={amendBookingSteps} activeStep={0} />
        </Box>

        <Box p={5} className='page-content'>
          <Box>
            <AmendBookingTypeComponent.FormBody
              bookingTypeData={booking}
              isEdit={isEdit.current}
            />
          </Box>
          <Box pt={5}>
            <ViewScheduleComponent.FormBody
              scheduleData={booking}
              showDetails
            />
          </Box>
          <Box pt={5}>
            <ShipmentComponent.FormBody
              placeOfReceiptKeyRef={placeOfReceiptKeyRef.current}
              placeOfDeliveryKeyRef={placeOfDeliveryKeyRef.current}
              //countryCodeByPortCodeRef={countryCodeByPortCodeRef}
              placeOfReceiptCodeField={placeOfReceiptCodeField}
              setPlaceOfReceiptField={setPlaceOfReceiptField}
              setPlaceOfDeliveryField={setPlaceOfDeliveryField}
              placeOfDeliveryCodeField={placeOfDeliveryCodeField}
              isEdit={isEdit.current}
              isShipperAmend
              forceExpand={forceExpand}
              setForceExpand={setForceExpand}
            />
          </Box>
          <Box pt={5}>
            <ContainerComponent.FormBody
              isEdit={isEdit.current}
            />
          </Box>
          <Box pt={5}>
            <ViewCargoComponent.FormBody
              cargo={booking.bookingCargoes}
              showDetails
            />
          </Box>
          <Box pt={5}>
            <ContactDetailsComponent.FormBody
              isEdit={isEdit.current}
              isShipperAmend
            />
          </Box>
          <Box pt={5}>
            <ViewPaymentComponent.FormBody paymentData={booking} showDetails />
          </Box>
          <Box pt={5}>
            <DocumentComponent.FormBody isEdit={isEdit.current} />
          </Box>
          <QuickScroll />
        </Box>
      </Paper>
      <Paper className='sticky-footer'>
        <Box alignItems='center'>
          <AmendBookingButtonComponent
            cancel={cancelBooking}
            submitAmendments={submitAmendments}
          />
        </Box>
      </Paper>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={cancelBooking}
        content={fbTranslatedTextsObject.dialogDiscardBookingContent}
        okMsg={uiTranslatedTextsObject.ok}
        cancelMsg={uiTranslatedTextsObject.cancel}
        title={fbTranslatedTextsObject.dialogDiscardBookingTitle}
      />
      <WarningDialog
        isDialogOpen={warningDialogOpen}
        closeDialog={() => setWarningDialogOpen(false)}
        okDialog={() => setWarningDialogOpen(false)}
        content={fbTranslatedTextsObject.formErrorWarning}
        //content={"There is error in your form. Please correct the error before submit the form"}
        okMsg={uiTranslatedTextsObject.ok}
      />
      <ConfirmDialog
        isConfirmDialogOpen={ammendConfirmDialogOpen}
        closeDialog={() => setAmmendConfirmDialogOpen(false)}
        confirmDialog={proceedAmendments}
        content={contentMessage}
        okMsg={uiTranslatedTextsObject.confirm}
        cancelMsg={uiTranslatedTextsObject.cancel}
        title={uiTranslatedTextsObject.confirm}
      />
    </Box>
  )
}

const MyBookingAmendFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
  // toClientDataFormat: toClientDataFormat,
  // toServerDataFormat: toServerDataFormat
})

export default MyBookingAmendFormProperties
