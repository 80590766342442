import { useHistory } from 'react-router-dom'

import ViewOnlyBookingFormProperties from './ViewOnlyBookingFormProperties'
import React from 'react'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/PathMap_FreightBooking'

const {
  form: { CngViewForm }
} = components

function ViewOnlyBookingPage({ showNotification }) {
  const history = useHistory()

  function onSubmit(data) {
    console.log('submityyy', data)

    history.push('/')
    history.replace({ pathname: pathMap.VIEW_ONLY_BOOKING_BOOKING, state: data })
  }

  return (
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <ViewOnlyBookingFormProperties.FormFields
          disabled={false}
          isViewBk={true}
          showNotification={showNotification}
        />
      }
      formikProps={{
        ...ViewOnlyBookingFormProperties.formikProps,
        onSubmit: onSubmit
      }}
    />
  )
}

export default ViewOnlyBookingPage
