import * as calistaConstant from 'src/constants/CalistaConstant'

import { Box, Paper } from '@material-ui/core'
import { components, useServices } from 'cng-web-lib'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CngBackdrop from '../../../vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import FreightBookingTranslationText from '../../shared/FreightBookingTranslationText'
import GeneralStepperComponent from 'src/views/common/ui/GeneralStepperComponent'
import MyBookingAmendReviewButtonComponent from './MyBookingAmendReviewButtonComponent'
import SubmittedBookingButtonComponent from '../../components/SubmittedBookingButtonComponent'
import ViewBookingTypeComponent from '../../components/ViewBookingTypeComponent'
import ViewCargoComponent from '../../components/ViewCargoComponent'
import ViewContainerComponent from '../../components/ViewContainerComponent'
import ViewReeferComponent from '../../components/ViewReeferComponent'
import ViewScheduleComponent from '../../components/ViewScheduleComponent'
import ViewShipmentComponent from '../../components/ViewShipmentComponent'
import makeValidationSchema from 'src/views/freightbooking/shared/MakeValidationSchema'
import moment from 'moment'
import pathMap from 'src/paths/PathMap_FreightBooking'
import Utils from '../../shared/Utils'
import CommonUtils from 'src/views/common/utils/Utils'
import ViewContactComponent from '../../components/ViewContactComponent'
import ViewPaymentComponent from '../../components/ViewPaymentComponent'
import ViewDocumentComponent from '../../components/ViewDocumentComponent'
import QuickScroll from 'src/components/iconbutton/QuickScroll'

const DEFAULT_INITIAL_VALUES = Object.freeze({
    bookingId: '',
    setFreightBookingId: '',
    bookingStatus: '',
    ...ViewBookingTypeComponent.initialValues,
    ...ViewScheduleComponent.initialValues,
})

const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

const {
    table: { useDefaultNotification },
    CngGridItem
} = components

function FormFields({ disabled, showNotification, shouldHideMap }) {

    const translatedTextsObject = FreightBookingTranslationText()
    const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
    // const calistaConstant = CalistaConstant()
    //get data from useHistory

    const { createRecord } = useServices()
    const history = useHistory()
    const location = useLocation();
    const booking = location.state
    const containers = booking.bookingContainers;
    const cargo = booking.bookingCargoes == null ? [] : booking.bookingCargoes;
    const documents = getDocumentList()
    const [loading, setLoading] = useState(false)
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [bookingStep, setBookingStep] = useState(1)
    const bookingId = useRef()
    const freightBookingId = useRef()
    const [bookingStatus, setBookingStatus] = useState()
    const [isSubmitted, setIsSubmitted] = useState(false)

    function getDocumentList() {

        if (booking.bookingStatus == calistaConstant.BOOKING_STATUS_AMENDED) {
            return location.state.bookingDocuments == null ? [] : location.state.bookingDocuments
        } else {
            //step 2 , combine document and pending document list
            let pendingList = location.state.pendingBookingDocuments == null ? [] : processPendingDocList(location.state.pendingBookingDocuments)
            return location.state.bookingDocuments == null ? pendingList : location.state.bookingDocuments.concat(pendingList)
        }
    }


    function processPendingDocList(pendingDocList) {
        console.log('testdoc, pendingDocList start', pendingDocList)
        pendingDocList.forEach((doc) => {
            doc.docType = doc.fields[0].value
            doc.description = doc.fields[1].value
        })

        console.log('testdoc, pendingDocList end', pendingDocList)
        return pendingDocList
    }

    const {
        success: showSuccessNotification,
        error: showErrorNotification
    } = useDefaultNotification(showNotification)

    const onEdit = () => {

        history.push({
            pathname: pathMap.MY_BOOKING_AMEND,
            state: { booking: booking }
        })
    }

    function onSubmitBooking() {
        setLoading(true)

        Utils.convertDate(booking)

        // const dataArr = Object.entries(booking);
        // dataArr.map(([key, val]) => {

        //     if (Utils.dateTimeFields.includes(key)) {
        //         if (val != null && val != "") {
        //             var test = new moment(val, CommonUtils.UI_FORMAT_DATE_TIME).utc();
        //             booking[key] = test.format(CommonUtils.FORMAT_DATE_SERVER_DATE)
        //         }
        //     }

        //     else if (Utils.dateFields.includes(key)) {
        //         if (val != null && val != "") {
        //             var test2 = new moment(val, CommonUtils.UI_FORMAT_DATE).utc();
        //             booking[key] = test2.format(CommonUtils.FORMAT_DATE_SERVER_DATE)
        //         }
        //     }

        // });

        if (booking.pendingBookingDocuments && !Utils.isEmpty(booking.pendingBookingDocuments)) {
            uploadDocumentAndAmendBooking()
        } else {
            amendBooking(booking)
        }


    }

    function uploadDocumentAndAmendBooking() {

        setLoading(true)

        var formData = new FormData();
        console.log(booking.pendingBookingDocuments)

        booking.pendingBookingDocuments.map((file, i) => {
            var fileBlob = file.file
            console.log(fileBlob)
            formData.append("fileContents", fileBlob); // name of the file upload box 

        })



        function onSuccess(response) {
            console.log('connection-request onSuccess', response)
            // setLoading(false)
            if (response.errorMessages != null) {

                showErrorNotification(response.errorMessages)

            } else {
                //showSuccessNotification(`${translatedTextsObject.saveDraftSuccess} ${response.freightBookingId}`)
                console.log(response)
                let bookingDocs = booking.bookingDocuments || [] //to store the final booking documents
                if (response && response.length > 0) {
                    response.map((file, i) => {
                        let bookingDoc = {
                            docType: booking.pendingBookingDocuments[i].fields[0].value,
                            filename: file.fileName,
                            description: booking.pendingBookingDocuments[i].fields[1].value,
                            docId: file.id,
                            filesize: file.fileSize
                        }

                        bookingDocs.push(bookingDoc)
                    })
                }

                booking.bookingDocuments = bookingDocs

                amendBooking(booking)
            }
        }

        function onError(error) {
            console.log('connection-request error', error.message)
            setLoading(false)
            showErrorNotification(CommonUtils.getErrorMessage(error))
        }

        createRecord.execute(
            FreightBookingApiUrls.DOCUMENT_UPLOAD,
            formData,
            onSuccess,
            onError
        )
    }

    function amendBooking(newBookingData) {
        function onSuccess(response) {
            console.log('connection-request onSuccess', response)
            if (response.errorMessages != null) {
                showErrorNotification(response.errorMessages)
            } else {
                showSuccessNotification(`${translatedTextsObject.amendSuccess}`)
                // booking.bookingId = response.bookingId
                // booking.freightBookingId = response.freightBookingId
                newBookingData.bookingStatus = response.bookingStatus
            }
            setLoading(false)

            history.push({
                pathname: pathMap.MY_BOOKING_AMEND_SUBMITTED,
                state: newBookingData
            })
        }

        function onError(error) {
            console.log('connection-request error', error.message)
            setLoading(false)
            showErrorNotification(CommonUtils.getErrorMessage(error))
        }

        createRecord.execute(
            FreightBookingApiUrls.AMEND_BOOKING,
            newBookingData,
            onSuccess,
            onError
        )
    }

    const onDiscard = () => {
        setConfirmDialogOpen(true)
    }

    const navigateMyBooking = () => {
        history.push({
            pathname: pathMap.MY_BOOKING
        })
    }


    useEffect(() => {
        console.log('populate review representativeName ' + booking.representativeName)
        setBookingStatus(booking.bookingStatus)

        if (booking.bookingStatus != 'undefined') {
            if (booking.bookingStatus == calistaConstant.BOOKING_STATUS_AMENDED) {
                setIsSubmitted(true)
                setBookingStep(2)
            }
        }
    }, [])

    const amendBookingSteps = [
        translatedTextsObject.stepLabelAmendBooking,
        translatedTextsObject.stepLabelReviewAmendments,
        translatedTextsObject.stepLabelAmendmentsSubmitted
    ]

    return (
        <Box id="top-level-box">
            <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
                <CngBackdrop loading={loading} />
            </CngGridItem>
            <Paper>
                <Box alignItems='center' pr={15} pl={15}>
                    <GeneralStepperComponent steps={amendBookingSteps} activeStep={bookingStep} />
                </Box>

                {/* {JSON.stringify(location.state)} */}

                <Box p={5} borderTop={1} borderBottom={1} className="border-light-blue" >
                    <Box fontWeight={600} fontSize="h3.fontSize" >
                        <Box display={isSubmitted ? 'none' : ''}>
                            {translatedTextsObject.reviewBooking}
                        </Box>
                        <Box display={isSubmitted ? '' : 'none'}>
                            <Box display="flex" flexDirection="row">
                                {translatedTextsObject.bookingSubmitted}
                                <Box pl={1.5}>
                                    <CheckCircleOutlineOutlinedIcon className='light-green' />
                                </Box>

                            </Box>
                            <Box className='info-label' pt={2} mb={-2}>
                                {translatedTextsObject.bookingSubmittedOn}
                                {moment().format(' MMMM Do yyyy')}
                            </Box>
                        </Box>
                    </Box>
                    <Box pt={5}>
                        <ViewBookingTypeComponent.FormBody bookingTypeData={booking} />
                    </Box>
                    <Box pt={5}>
                        <ViewScheduleComponent.FormBody scheduleData={booking} showDetails />
                    </Box>
                    <Box pt={5}>
                        <ViewShipmentComponent.FormBody shipmentData={booking} showDetails />
                    </Box>
                    <Box pt={5}>
                        <ViewContainerComponent.FormBody containers={containers} showDetails />
                    </Box>
                    {booking.reeferAvailble &&
                        <Box pt={5}>
                            <ViewReeferComponent.FormBody data={booking} />
                        </Box>
                    }
                    <Box pt={5}>
                        <ViewCargoComponent.FormBody cargo={cargo} showDetails />
                    </Box>

                    {documents.length > 0 && (
                        <Box pt={5}>
                            <ViewDocumentComponent.FormBody document={documents} />
                        </Box>
                    )}

                    <Box pt={5}>
                        <ViewContactComponent.FormBody contactData={booking} />
                    </Box>

                    <Box pt={5}>
                        <ViewPaymentComponent.FormBody paymentData={booking} />
                    </Box>
                    <QuickScroll />
                </Box>
            </Paper>
            <Paper className='sticky-footer'>
                {/* <Paper> */}
                <Box alignItems='center'>
                    {isSubmitted ?
                        <SubmittedBookingButtonComponent
                            onMyBooking={navigateMyBooking}
                        />
                        : <MyBookingAmendReviewButtonComponent

                            onEdit={onEdit}
                            onDiscard={onDiscard}
                            onSubmitBooking={onSubmitBooking}
                        />}

                </Box>
            </Paper>
            <ConfirmDialog
                isConfirmDialogOpen={confirmDialogOpen}
                closeDialog={() => setConfirmDialogOpen(false)}
                confirmDialog={navigateMyBooking}
                content={translatedTextsObject.dialogDiscardBookingContent}
                okMsg={uiTranslatedTextsObject.ok}
                cancelMsg={uiTranslatedTextsObject.cancel}
                title={translatedTextsObject.dialogDiscardBookingTitle}
            />
        </Box>
    )
}

const MyBookingAmendReviewFormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    FormFields: FormFields
    // toClientDataFormat: toClientDataFormat,
    // toServerDataFormat: toServerDataFormat
})


export default MyBookingAmendReviewFormProperties