import { Yup } from 'cng-web-lib'
import * as ValidationRegex from './ValidationRegex'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function ApprovalValidationSchema() {


  const validationMessageTranslation = ValidationMessageTranslationText()

  return Yup.object({

    carrierRefNo: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),
    bookingStatus: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),

  })
}

export default ApprovalValidationSchema

