import { Yup } from 'cng-web-lib'
import * as ValidationRegex from './ValidationRegex'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function CargoValidationSchema() {

  const validationMessageTranslation = ValidationMessageTranslationText()

  function validateWeightVolumeNumber(value, name) {
    if (!!value) {
      return String(value).match(ValidationRegex.WeightVolumeRegx);
    } else {

      return true;
    }
  }

  function validateHSCode(value) {
    return ValidationRegex.HSCodeRegx.test(value)
  }


  return Yup.object({

    bookingCargoes: Yup.array().when("hazardous", {
      is: false,
      then: Yup.array()
        .when("saveDraft", {
          is: false,
          then: Yup.array(
            Yup.object({
              quantity: Yup.lazy((value)=> value===''? Yup.string():
                  Yup.number().integer(validationMessageTranslation.IntegerOnlyMsg)
                  .nullable().min(0, validationMessageTranslation.valueTooSmallMsg)
                  .max(99999, validationMessageTranslation.valueTooBigMsg) ),
              cargoDesc: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),
              hsCode: Yup.string().test("hsCode", validationMessageTranslation.invalidHSCode, value => validateHSCode(value)).nullable(),
              grossVolume: Yup.mixed().test("grossVolume", validationMessageTranslation.numberErrorMsg, value => validateWeightVolumeNumber(value)),
              grossWeight: Yup.mixed().test("grossWeight", validationMessageTranslation.numberErrorMsg, value => validateWeightVolumeNumber(value)),
              netVolume: Yup.mixed().test("netVolume", validationMessageTranslation.numberErrorMsg, value => validateWeightVolumeNumber(value)),
              netWeight: Yup.mixed().test("netWeight", validationMessageTranslation.numberErrorMsg, value => validateWeightVolumeNumber(value)),
              vgmWeight: Yup.mixed().test("totalVgmWeight", validationMessageTranslation.numberErrorMsg, value => validateWeightVolumeNumber(value)),
            }),
          ),
          otherwise: Yup.array(
            Yup.object({
              quantity: Yup.lazy((value)=> value===''? Yup.string():
              Yup.number().integer(validationMessageTranslation.IntegerOnlyMsg)
              .nullable().min(0, validationMessageTranslation.valueTooSmallMsg)
              .max(99999, validationMessageTranslation.valueTooBigMsg) ),
              hsCode: Yup.string().test("hsCode", validationMessageTranslation.invalidHSCode, value => validateHSCode(value)).nullable(),
              grossVolume: Yup.mixed().test("grossVolume", validationMessageTranslation.numberErrorMsg, value => validateWeightVolumeNumber(value)),
              grossWeight: Yup.mixed().test("grossWeight", validationMessageTranslation.numberErrorMsg, value => validateWeightVolumeNumber(value)),
              netVolume: Yup.mixed().test("netVolume", validationMessageTranslation.numberErrorMsg, value => validateWeightVolumeNumber(value)),
              netWeight: Yup.mixed().test("netWeight", validationMessageTranslation.numberErrorMsg, value => validateWeightVolumeNumber(value)),
              vgmWeight: Yup.mixed().test("totalVgmWeight", validationMessageTranslation.numberErrorMsg, value => validateWeightVolumeNumber(value)),
            }),
          )

        }),
      otherwise: Yup.array().nullable()
    }),


    bookingDGCargoes: Yup.array().when("hazardous", {
      is: true,
      then: Yup.array()
        .when("saveDraft", {
          is: false,
          then: Yup.array(
            Yup.object({

              hsCode: Yup.string().test("hsCode", validationMessageTranslation.invalidHSCode, value => validateHSCode(value)).nullable(),
              cargoDesc: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),
            })
          ),
          otherwise: Yup.array(
            Yup.object({
              hsCode: Yup.string().test("hsCode", validationMessageTranslation.invalidHSCode, value => validateHSCode(value)).nullable(),
            })
          )
        }),
      otherwise: Yup.array().nullable()

    })

  })

}

export default CargoValidationSchema


