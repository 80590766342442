import { Yup } from 'cng-web-lib'
import * as ValidationRegex from './ValidationRegex'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function DGCargoPhysicalChemicalValidationSchema() {


  const validationMessageTranslation = ValidationMessageTranslationText()

  return Yup.object({

    // sadt: Yup.number().integer(validationMessageTranslation.IntegerOnlyMsg).min(0, validationMessageTranslation.valueTooSmallMsg).max(9999999999, validationMessageTranslation.valueTooBigMsg).nullable(),
    // emergencyTemperature: Yup.number().integer(validationMessageTranslation.IntegerOnlyMsg).min(0, validationMessageTranslation.valueTooSmallMsg).max(9999999999, validationMessageTranslation.valueTooBigMsg).nullable(),
    // controlTemperature: Yup.number().integer(validationMessageTranslation.IntegerOnlyMsg).min(0, validationMessageTranslation.valueTooSmallMsg).max(9999999999, validationMessageTranslation.valueTooBigMsg).nullable(),
    // flashPoint: Yup.number().integer(validationMessageTranslation.IntegerOnlyMsg).min(0, validationMessageTranslation.valueTooSmallMsg).max(999, validationMessageTranslation.valueTooBigMsg).nullable(),
    // boilingPoint: Yup.number().integer(validationMessageTranslation.IntegerOnlyMsg).min(0, validationMessageTranslation.valueTooSmallMsg).max(9999999999, validationMessageTranslation.valueTooBigMsg).nullable(),


    sadt: Yup.number().integer(validationMessageTranslation.IntegerOnlyMsg).min(0, validationMessageTranslation.valueTooSmallMsg).max(9999999999, validationMessageTranslation.valueTooBigMsg)

      .when('sadtUom', {
        is: value => value && value.length > 0,
        then: Yup.number().required(validationMessageTranslation.requiredMessage).nullable(),

        otherwise: Yup.number().nullable()

      }),
    sadtUom: Yup.string().nullable()
      .test("sadt", validationMessageTranslation.requiredMessage, function (
        val
      ) {
        const { sadt } = this.parent;
        if (sadt) return val != null
        return true
      }),

    emergencyTemperature: Yup.number().integer(validationMessageTranslation.IntegerOnlyMsg).min(0, validationMessageTranslation.valueTooSmallMsg).max(9999999999, validationMessageTranslation.valueTooBigMsg)
      .when('emergencyTemperatureUom', {
        is: value => value && value.length > 0,
        then: Yup.number().required(validationMessageTranslation.requiredMessage).nullable(),

        otherwise: Yup.number().nullable()

      }),

    emergencyTemperatureUom: Yup.string().nullable()
      .test("emergencyTemperature", validationMessageTranslation.requiredMessage, function (
        val
      ) {
        const { emergencyTemperature } = this.parent;
        if (emergencyTemperature) return val != null
        return true
      }),

    controlTemperature: Yup.number().integer(validationMessageTranslation.IntegerOnlyMsg).min(0, validationMessageTranslation.valueTooSmallMsg).max(9999999999, validationMessageTranslation.valueTooBigMsg)
      .when('controlTemperatureUom', {
        is: value => value && value.length > 0,
        then: Yup.number().required(validationMessageTranslation.requiredMessage).nullable(),

        otherwise: Yup.number().nullable()

      }),

    controlTemperatureUom: Yup.string().nullable()
      .test("controlTemperature", validationMessageTranslation.requiredMessage, function (
        val
      ) {
        const { controlTemperature } = this.parent;
        if (controlTemperature) return val != null
        return true
      }),

    flashPoint: Yup.number().integer(validationMessageTranslation.IntegerOnlyMsg).min(-999, validationMessageTranslation.valueTooSmallMsg).max(999, validationMessageTranslation.valueTooBigMsg)
      .when('flashPointUom', {
        is: value => value && value.length > 0,
        then: Yup.number().required(validationMessageTranslation.requiredMessage).nullable(),

        otherwise: Yup.number().nullable()

      }),

    flashPointUom: Yup.string().nullable()
      .test("flashPoint", validationMessageTranslation.requiredMessage, function (
        val
      ) {
        const { flashPoint } = this.parent;
        if (flashPoint) return val != null
        return true
      }),

    boilingPoint: Yup.number().integer(validationMessageTranslation.IntegerOnlyMsg).min(0, validationMessageTranslation.valueTooSmallMsg).max(9999999999, validationMessageTranslation.valueTooBigMsg)
      .when('boilingPointUom', {
        is: value => value && value.length > 0,
        then: Yup.number().required(validationMessageTranslation.requiredMessage).nullable(),

        otherwise: Yup.number().nullable()

      }),

    boilingPointUom: Yup.string().nullable()
      .test("boilingPoint", validationMessageTranslation.requiredMessage, function (
        val
      ) {
        const { boilingPoint } = this.parent;
        if (boilingPoint) return val != null
        return true
      }),


  })
}

export default DGCargoPhysicalChemicalValidationSchema

