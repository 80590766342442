import { Yup } from 'cng-web-lib'
import * as ValidationRegex from './ValidationRegex'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function DGCargoHazardousIdentificationValidationSchema() {
  const validationMessageTranslation = ValidationMessageTranslationText()

  function validateAmountOfFumigation(value, name) {
    if (!!value) {
      return String(value).match(/^.{1,11}$/)
    } else {
      return true
    }
  }

  function validateDGWeightVolumeNumber(value, name) {
    if (!!value) {
      return String(value).match(ValidationRegex.dgWeightVolumeRegx)
    } else {
      return true
    }
  }

  return Yup.object({
    radioactiveMeasurement: Yup.mixed().test(
      'radioactiveMeasurement',
      validationMessageTranslation.numericErrorMsg,
      (value) => validateDGWeightVolumeNumber(value) //format pattern 999999.999
    ),
    acidConcentration: Yup.mixed().test(
      'acidConcentration',
      validationMessageTranslation.numericErrorMsg,
      (value) => validateDGWeightVolumeNumber(value) //format pattern 999999.999
    ),
    dateOfFumigation: Yup.date()
      .nullable()
      .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg),
    amountOfFumigation: Yup.number()
      .nullable()
      .min(-9999999999, validationMessageTranslation.valueTooSmallMsg)
      .max(99999999999, validationMessageTranslation.valueTooBigMsg)
      .test(
        'amountOfFumigation',
        validationMessageTranslation.valueTooBigMsg,
        (value) => validateAmountOfFumigation(value)
      )
  })
}

export default DGCargoHazardousIdentificationValidationSchema
