
import ContactValidationSchema from 'src/views/freightbooking/shared/validation/ContactValidationSchema'
import BookingTypeValidationSchema from 'src/views/freightbooking/shared/validation/BookingTypeValidationSchema'
import ScheduleValidationSchema from 'src/views/freightbooking/shared/validation/ScheduleValidationSchema'
import ShipmentValidationSchema from 'src/views/freightbooking/shared/validation/ShipmentValidationSchema'
import ContainerValidationSchema from 'src/views/freightbooking/shared/validation/ContainerValidationSchema'
import CargoValidationSchema from 'src/views/freightbooking/shared/validation/CargoValidationSchema'
import DocumentValidationSchema from 'src/views/freightbooking/shared/validation/DocumentValidationSchema'

function useNewBookingMakeValidationSchema() {


  return BookingTypeValidationSchema()
    .concat(ContactValidationSchema())
    .concat(ScheduleValidationSchema())
    .concat(ShipmentValidationSchema())
    .concat(ContainerValidationSchema())
    .concat(CargoValidationSchema())
    .concat(DocumentValidationSchema())

}

export default useNewBookingMakeValidationSchema
