import CompanyPreferenceKeys from 'src/constants/locale/key/CompanyPreference'
import FreightBookingKeys from 'src/constants/locale/key/FreightBooking'
import Namespace from 'src/constants/locale/Namespace'
import { useTranslation } from 'cng-web-lib'

const FreightBookingTranslationText = () => {
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.FREIGHT_BOOKING,
    Namespace.COMPANY_PREFERENCE,
    Namespace.COMMON
  ])

  let title = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.FREIGHT_BOOKING_TITLE
  )

  let bookFromScheduleTitle = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOOK_FROM_SCHEDULE
  )

  let stepLabelCreateBooking = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.STEP_LABEL_CREATE_BOOKING
  )

  let stepLabelReviewBooking = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.STEP_LABEL_REVIEW_BOOKING
  )

  let stepLabelBookingSubmitted = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.STEP_LABEL_BOOKING_SUBMITTED
  )

  let freightBookingId = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.FREIGHT_BOOKING_ID
  )

  let freightBookingRefNo = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.FREIGHT_BOOKING_REF_NO
  )

  let bookingId = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOOKING_ID
  )

  let lastModifiedOn = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.LAST_MODIFIED_ON
  )

  let stepLabelAmendBooking = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.STEP_LABEL_AMEND_BOOKING
  )

  let stepLabelReviewAmendments = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.STEP_LABEL_REVIEW_AMENDMENTS
  )

  let stepLabelAmendmentsSubmitted = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.STEP_LABEL_AMENDMENTS_SUBMITTED
  )

  let stepLabelSubmit = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.STEP_LABEL_SUBMIT
  )

  let bookingRefNo = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOOKING_REF_NO
  )

  let shipperRefNo = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SHIPPER_REF_NO
  )
  let containersType = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CONTAINERS_TYPE
  )
  let departingOn = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.DEPARTING_ON
  )
  let cutoff = translate(Namespace.FREIGHT_BOOKING, FreightBookingKeys.CUTOFF)
  let shipper = translate(Namespace.FREIGHT_BOOKING, FreightBookingKeys.SHIPPER)

  let saveDraftSuccess = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SAVE_DRAFT_SUCCESS
  )
  let submitSuccess = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SUBMIT_SUCCESS
  )
  let copySuccess = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.COPY_SUCCESS
  )
  let cancelSuccess = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CANCEL_SUCCESS
  )
  let editSuccess = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.EDIT_SUCCESS
  )
  let deleteSuccess = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.DELETE_SUCCESS
  )
  let archivedSuccess = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.ARCHIVED_SUCCESS
  )
  let approveSuccess = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.APPROVE_SUCCESS
  )
  let rejectSuccess = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.REJECT_SUCCESS
  )

  let amendSuccess = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.AMEND_SUCCESS
  )

  let helpdeskAmendBookingStatusSuccess = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.HELPDESK_AMEND_BOOKING_STATUS_SUCCESS
  )

  let bookingStatus = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOOKING_STATUS
  )

  //booking type
  let bookingType = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOOKING_TYPE
  )

  let shipmentType = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.TYPE_OF_SHIPMENT
  )

  let hazardous = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.HAZARDOUS
  )
  let hazardIdentification = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.HAZARD_IDENTIFICATION
  )
  let radioactiveMeasurement = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.RADIOACTIVE_MEASUREMENT
  )
  let acidConcentration = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.ACID_CONCENTRATION
  )
  let marinePollutantStatus = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.MARINE_POLLUTANT_STATUS
  )
  let tremCardNo = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.TREM_CARD_NUMBER
  )
  let regulatoryInfo = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.REGULATORY_INFORMATION
  )
  let radioactiveGoodsInfo = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.RADIOACTIVE_GOODS_INFORMATION
  )
  let placardInfo = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PLACARD_INFORMATION
  )
  let additionalHazmatSettings = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.ADDITIONAL_HAZMAT_SETTINGS
  )
  let poisonInhalationHazardZone = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.POISON_INHALATION_HAZARD_ZONE
  )
  let aggregationState = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.AGGREGATION_STATE
  )
  let dateOfFumigation = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.DATE_OF_FUMIGATION
  )
  let localCategory = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.LOCAL_CATEGORY
  )
  let physicalChemicalProps = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PHYSICAL_AND_CHEMICAL_PROPERTIES
  )
  let flashpoint = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.FLASHPOINT
  )
  let boilingPoint = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOILING_POINT
  )
  let sadt = translate(Namespace.FREIGHT_BOOKING, FreightBookingKeys.SADT)
  let controlTemp = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CONTROL_TEMPERATURE
  )
  let emergencyTemp = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.EMERGENCY_TEMPERATURE
  )
  let forwardHubScheme = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.FORWARD_HUB_SCHEME
  )

  let additionalBookingTypeDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.ADDITIONAL_BOOKING_TYPE_DETAILS
  )

  //Schedule
  let schedule = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SCHEDULE
  )

  let carrierNvoccBookingAgent = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CARRIER_NVOCC_BOOKING_AGENT
  )

  let portOfLoading = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PORT_OF_LOADING
  )

  let portOfDischarge = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PORT_OF_DISCHARGE
  )

  let polPlannedEta = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.POL_PLANNED_ETA
  )

  let podPlannedEta = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.POD_PLANNED_ETA
  )

  let polPlannedEtd = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.POL_PLANNED_ETD
  )

  let podPlannedEtd = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.POD_PLANNED_ETD
  )

  let dischargeTerminal = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.DISCHARGE_TERMINAL
  )

  let vesselName = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.VESSEL_NAME
  )

  let internationalVoyageNumber = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.INTERNATIONAL_VOYAGE_NUMBER
  )

  let voyageNumber = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.VOYAGE_NUMBER
  )

  let voyageNo= translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.VOYAGE_NO
  )

  let additionalScheduleDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.ADDITIONAL_SCHEDULE_DETAILS
  )

  //Container Details
  let containerDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CONTAINER_DETAILS
  )

  let oldContainer = translate(
    Namespace.COMMON,
    FreightBookingKeys.OLD
  )

  let newContainer = translate(
    Namespace.COMMON,
    FreightBookingKeys.NEW
  )

  let deleted = translate(
    Namespace.CALISTA_UI_COMPONENT,
    FreightBookingKeys.DELETED
  )

  let added = translate(
    Namespace.CALISTA_UI_COMPONENT,
    FreightBookingKeys.ADDED
  )

  let removed = translate(
    Namespace.CALISTA_UI_COMPONENT,
    FreightBookingKeys.REMOVED
  )

  let updated = translate(
    Namespace.CALISTA_UI_COMPONENT,
    FreightBookingKeys.UPDATED
  )

  let renamed = translate(
    Namespace.CALISTA_UI_COMPONENT,
    FreightBookingKeys.RENAMED
  )

  let quantity = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.QUANTITY
  )

  let type = translate(Namespace.FREIGHT_BOOKING, FreightBookingKeys.TYPE)

  let size = translate(Namespace.FREIGHT_BOOKING, FreightBookingKeys.SIZE)

  let containerSizeType = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CONTAINER_SIZE_TYPE
  )

  let heightType = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.HEIGHT_TYPE
  )

  let status = translate(Namespace.FREIGHT_BOOKING, FreightBookingKeys.STATUS)

  let shipperOwned = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SHIPPER_OWNED
  )

  let addAContainer = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.ADD_A_CONTAINER
  )

  let cloneContainer = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CLONE_CONTAINER
  )

  //Reefer Details
  let reeferDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.REEFER_DETAILS
  )

  let nonActiveReefer = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.NON_ACTIVE_REEFER
  )

  let equipmentTemp = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.EQUIPMENT_TEMP
  )

  let tempUom = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.TEMP_UOM
  )

  let specialHandling = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SPECIAL_HANDLING
  )

  let superFreezerService = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SUPER_FREEZER_SERVICE
  )

  let gensetRequired = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.GENSET_REQUIRED
  )

  let inTransitColdSterilization = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.IN_TRANSIT_COLD_STERILIZATION
  )

  let numberOfTempProbes = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.NUMBER_OF_TEMP_PROBES
  )

  let numberOfUSDProbes = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.NUMBER_OF_USD_PROBES
  )

  let tempVariance = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.TEMP_VARIANCE
  )

  let ventSetting = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.VENT_SETTING
  )

  let equipmentAirFlow = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.EQUIPMENT_AIRFLOW
  )

  let equipmentAirFlowUom = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.EQUIPMENT_AIRFLOW_UOM
  )

  let equipmentControlledAtmosphere = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.EQUIPMENT_CONTROLLED_ATMOSPHERE
  )

  let equipmentControlledAtmosphereCO2 = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.EQUIPMENT_CONTROLLED_ATMOSPHERE_CO2
  )

  let equipmentControlledAtmosphereN2 = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.EQUIPMENT_CONTROLLED_ATMOSPHERE_N2
  )

  let equipmentControlledAtmosphereO2 = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.EQUIPMENT_CONTROLLED_ATMOSPHERE_O2
  )

  let humidty = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.HUMIDTY
  )

  let equipmentHumidity = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.EQUIPMENT_HUMIDITY
  )

  let humidityUom = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.HUMIDITY_UOM
  )

  let equipmentComments = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.EQUIPMENT_COMMENTS
  )

  let additionalReeferDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.ADDITIONAL_REEFER_DETAILS
  )

  let percentofMoistureInAir = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PERCENT_OF_MOISTURE_IN_AIR
  )

  //Cargo Details
  let cargo = translate(Namespace.FREIGHT_BOOKING, FreightBookingKeys.CARGO)
  let dgCargo = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.DG_CARGO
  )
  let cargoId = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CARGO_ID
  )

  let gross = translate(Namespace.FREIGHT_BOOKING, FreightBookingKeys.GROSS)

  let net = translate(Namespace.FREIGHT_BOOKING, FreightBookingKeys.NET)

  let vgm = translate(Namespace.FREIGHT_BOOKING, FreightBookingKeys.VGM)

  let cargoDesc = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CARGO_DESC
  )

  let hsCode = translate(Namespace.FREIGHT_BOOKING, FreightBookingKeys.HSCODE)

  let packageType = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PACKAGE_TYPE
  )

  let grossVolume = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.GROSS_VOLUME
  )

  let grossWeight = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.GROSS_WEIGHT
  )

  let dgGrossWeight = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.DG_GROSS_WEIGHT
  )

  let netVolume = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.NET_VOLUME
  )

  let netWeight = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.NET_WEIGHT
  )

  let totalVgmWeight = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.TOTAL_VGM_WEIGHT
  )

  let totalCargoWeight = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.TOCAL_CARGO_WEIGHT
  )

  let grossWeightNoUnit = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.GROSS_WEIGHT_NO_UNIT
  )

  let netWeightNoUnit = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.NET_WEIGHT_NO_UNIT
  )

  let vgmWeight = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.VGM_WEIGHT
  )

  let grossVolumeNoUnit = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.GROSS_VOLUME_NO_UNIT
  )

  let netVolumeNoUnit = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.NET_VOLUME_NO_UNIT
  )

  let additionalCargoDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.ADDITIONAL_CARGO_DETAILS
  )

  let addCargo = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.ADD_CARGO
  )

  let addDGDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.ADD_DG_DETAILS
  )

  let hazardousDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.HAZARDOUS_DETAILS
  )

  let marksAndNumbers = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.MARKS_AND_NUMBERS
  )

  let technicalName = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.TECHNICAL_NAME
  )
  let properShippingName = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PROPER_SHIPPING_NAME
  )
  let IMDGCodePage = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.IMDG_CODE_PAGE
  )
  let regulationPageNumber = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.REGULATION_PAGE_NUMBER
  )
  let regulationVersionNumber = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.REGULATION_VERSION_NUMBER
  )
  let EMSNumber = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.EMS_NUMBER
  )

  let uom = translate(Namespace.FREIGHT_BOOKING, FreightBookingKeys.UOM)
  let limitedQTY = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.LIMITED_QTY
  )
  let expectedQTY = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.EXPECTED_QTY
  )

  let additionalClassIdenDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.ADDITIONAL_CLASSIDEN_DETAILS
  )
  let additionalPackWeightDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.ADDITIONAL_PACK_WEIGHT_DETAILS
  )
  let additionalHazardIdenDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.ADDITIONAL_HAZARD_IDEN_DETAILS
  )
  let additionalPhysiChemiDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.ADDITIONAL_PHYSICHEMI_DETAILS
  )

  let packingGroup = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PACKING_GROUP
  )
  let ibcPackageCode = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.IBC_PACKAGE_CODE
  )
  let packagingDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PACKAGING_DETAILS
  )

  let generalComments = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.GENERAL_COMMENTS
  )

  let fumigationType = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.FUMIGATION_TYPE
  )
  let amountOfFumigation = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.AMOUNT_OF_FUMIGATION
  )

  let inhalantHazard = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.INHALANT_HAZARD
  )
  let emptyUncleanedReceptacle = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.EMPTY_UNCLEANED_RECEPTACLE
  )
  let transportOfDangerousGoodsInLimitedQuantities = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.TRANSPORT_OF_DANGEROUS_GOODS_IN_LIMITED_QUANTITIES
  )

  let reportableQuantity = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.REPORTABLE_QUANTITY
  )
  let netExplosiveContent = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.NET_EXPLOSIVE_CONTENT
  )

  //Shipment Details
  let shipment = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SHIPMENT_TTTLE
  )

  let moveType = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.MOVE_TYPE
  )

  let emptyPickup = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.EMPTY_PICKUP_TITLE
  )

  let emptyPickupInstruction = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.EMPTY_PICKUP_INSTR
  )

  let shipTo = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SHIP_TO_TITLE
  )

  let shipFrom = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SHIP_FROM_TITLE
  )

  let placeOfReceipt = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PLACE_OF_RECEIPT
  )

  let placeOfDelivery = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PLACE_OF_DELIVERY
  )

  let cargoReadyDate = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CARGO_READY_DATE
  )

  let cargoDeliveryDate = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CARGO_DESTINATION_DATE
  )

  let placeOfReceiptDesc = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PLACE_OF_RECEIPT_DESC
  )

  let placeOfDeliveryDesc = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PLACE_OF_DELIVERY_DESC
  )

  let placeOfReceiptETD = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PLACE_OF_RECEIPT_ETD
  )

  let placeOfDeliveryETA = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PLACE_OF_DELIVERY_ETA
  )
  let loadTerminal = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.LOAD_TERMINAL
  )

  let destinationCode = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.DESTINATION_CODE
  )

  let finalDestination = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.FINAL_DESTINATION
  )

  let shipperRemarks = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SHIPPER_REMARKS
  )

  let compName = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.COMP_NAME
  )

  let company = translate(Namespace.FREIGHT_BOOKING, FreightBookingKeys.COMPANY)

  let compAddress = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.COMP_ADDRESS
  )

  let ContactName = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CONTACT_NAME
  )

  let contact = translate(Namespace.FREIGHT_BOOKING, FreightBookingKeys.CONTACT)

  let ContactPhoneNo = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CONTACT_PHONE_NO
  )

  let emptyPickupDate = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.EMPTY_PICKUP_DATE
  )

  let fullPickupDate = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.FULL_PICKUP_DATE
  )

  let shipFromDatePickup = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SHIP_FROM_DATE_PICKUP
  )

  let shipFromDatePositioning = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SHIP_FROM_DATE_POSITIONING
  )

  let shipToDateDelivery = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SHIP_TO_DATE_DELIVERY
  )

  let additionalShipmentDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.ADDITIONAL_SHIPMENT_DETAILS
  )

  let PoRETDAndPoDETAWarningMessage = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PORETD_AND_PODETA_WARNING_MESSAGE
  )

  let PoRETDWarningMessage = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PORETD_WARNING_MESSAGE
  )

  let PoDETAWarningMessage = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PODETA_WARNING_MESSAGE
  )

  //Contact Details
  let contactDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CONTACT_DETAILS
  )

  let additionalContactDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.ADDITIONAL_CONTACT_DETAILS
  )

  let representativeName = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.REPRESENTATIVE_NAME
  )

  let representativeEmail = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.REPRESENTATIVE_EMAIL
  )

  let representativeContactNumber = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.REPRESENTATIVE_CONTACT_NUMBER
  )

  let references = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.REFERENCES
  )

  let contractNumber = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CONTRACT_NUMBER
  )

  let purchaseOrderNumber = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PURCHASE_ORDER_NUMBER
  )

  let shipperRefNumber = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SHIPPER_REF_NUMBER
  )

  let forwarderRefNumber = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.FORWARDER_REF_NUMBER
  )
  let outboundRefNo = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.OUTBOUND_REF_NO
  )

  let preAssignmentBookingNumber = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PRE_ASSIGNMENT_BOOKING_NUMBER
  )

  let parties = translate(Namespace.FREIGHT_BOOKING, FreightBookingKeys.PARTIES)

  let contractPartyName = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CONTRACT_PARTY_NAME
  )

  let mainNotifyParty = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.MAIN_NOTIFY_PARTY
  )

  let shipperName = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SHIPPER_NAME
  )

  let consigneeName = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CONSIGNEE_NAME
  )

  let forwarderName = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.FORWARDER_NAME
  )

  let preShipmentNotificationContacts = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PRE_SHIPMENT_NOTIFICATION_CONTACTS
  )

  let addContactDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.ADD_CONTACT_DETAILS
  )

  let partnerEmailNtf = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PARTNER_EMAIL_NTF
  )

  let emailToNotify = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.EMAIL_TO_NOTIFY
  )

  let notifyBookingStatus = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.NOTIFY_BOOKING_STATUS
  )

  let notifyEventMilestones = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.NOTIFY_EVENT_MILESTONES
  )

  let partnerEmailCaption = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PARTNER_EMAIL_CAPTION
  )

  //payment instruction
  let paymentInstruction = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PAYMENT_INSTRUCTION
  )

  let chargeType = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CHARGE_TYPE
  )

  let payer = translate(Namespace.FREIGHT_BOOKING, FreightBookingKeys.PAYER)

  let freightTerm = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.FREIGHT_TERM
  )

  let pmtLoc = translate(Namespace.FREIGHT_BOOKING, FreightBookingKeys.PMT_LOC)

  let addPaymentInstruction = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.ADD_PAYMENT_INSTRUCTION
  )
  let paymentLocation = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PAYMENT_LOCATION
  )

  //document
  let document = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.DOCUMENT
  )
  let documents = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.DOCUMENTS
  )
  let attachDocument = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.ATTACH_DOCUMENT
  )

  let documentInstruction = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.DOCUMENT_INSTRUCTION
  )

  let fileType = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.FILE_TYPE
  )

  let fileDesc = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.FILE_DESC
  )

  let dialogDiscardBookingTitle = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.DIALOG_DISCARD_BOOKING_TITLE
  )

  let dialogDiscardBookingContent = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.DIALOG_DISCARD_BOOKING_CONTENT
  )

  let dialogCancelBookingTitle = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.DIALOG_CANCEL_BOOKING_TITLE
  )

  let dialogDeleteBookingTitle = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.DIALOG_DELETE_BOOKING_TITLE
  )

  let dialogArchiveBookingTitle = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.DIALOG_ARCHIVE_BOOKING_TITLE
  )

  let manageBookingTitle = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.MANAGE_BOOKING_TITLE
  )

  let viewOnlyBookingTitle = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.VIEW_ONLY_BOOKING_TITLE
  )

  let backToViewOnlyBookingPage = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BACK_TO_VIEW_ONLY_BOOKING_PAGE
  )

  let bookingDetailsTitle = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOOKING_DETAILS_TITLE
  )

  let dialogChangeStatusGeneralContent = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.DIALOG_CHANGE_STATUS_GENERAL_CONENT
  )

  let dialogSwitchDGContent = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.DIALOG_SWITCH_DG_CONTENT
  )

  let formErrorWarning = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.FORM_ERROR_WARNING
  )

  // Review Booking
  let reviewBooking = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.REVIEW_BOOKING
  )
  let containers = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CONTAINERS
  )
  let container = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CONTAINER
  )
  // Booking status header
  let bookingSubmitted = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOOKING_SUBMITTED
  )
  let bookingApproved = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOOKING_APPROVED
  )
  let bookingClosed = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOOKING_CLOSED
  )
  let bookingCancelled = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOOKING_CANCELLED
  )
  let bookingAmended = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOOKING_AMENDED
  )
  let bookingRejected = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOOKING_REJECTED
  )
  let bookingSubmittedOn = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOOKING_SUBMITTED_ON
  )

  let bookingApprovedOn = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOOKING_APPROVED_ON
  )

  //My Booking
  let myBooking = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.MY_BOOKING
  )
  let bookingFilterDate = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOOKING_FILTER_DATE
  )

  let lastModifiedDate = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.LAST_MODIFIED_DATE
  )

  let bookingFilterAll = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOOKING_FILTER_ALL
  )

  let bookingFilterDate15 = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOOKING_FILTER_DATE_15
  )

  let bookingFilterDate30 = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOOKING_FILTER_DATE_30
  )

  let bookingFilterDate60 = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOOKING_FILTER_DATE_60
  )

  let carrierComments = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CARRIER_COMMENTS
  )
  let pendingApproval = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PENDING_APPROVAL
  )
  let processed = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PROCESSED
  )
  let shown = translate(Namespace.FREIGHT_BOOKING, FreightBookingKeys.SHOWN)
  let submittedInfo = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SUBMITTED_INFO
  )
  let approvedInfo = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.APPROVED_INFO
  )
  let rejectedInfo = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.REJECTED_INFO
  )
  let amendedInfo = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.AMENDED_INFO
  )
  let cancelledInfo = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CANCELLED_INFO
  )
  let closedInfo = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CLOSED_INFO
  )
  let shipperComments = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SHIPPER_COMMENTS
  )
  let approval = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.APPROVAL
  )
  let carrierRemarks = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CARRIER_REMARKS
  )
  let bookingRefenceNumber = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOOKING_REFERENCE_NUMBER
  )
  let approveBooking = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.APPROVE_BOOKING
  )
  let declineBooking = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.DECLINE_BOOKING
  )

  let classificationIdentication = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CLASSIFICATION_IDENTICATION
  )
  let imoClass = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.IMO_CLASS
  )
  let undgNumber = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.UNDG_NUMBER
  )
  let shippingName = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SHIPPING_NAME
  )
  let packagingWeight = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PACKAGING_WEIGHT
  )
  let outerPkgQTY = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.OUTER_PKG_QTY
  )
  let innerPkgQTY = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.INNER_PKG_QTY
  )
  let forEmergency = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.FOR_EMERGENCY
  )
  let emergencyContactNumber = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.EMERGENCY_CONTACT_NUMBER
  )
  let emergencyContact = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.EMERGENCY_CONTACT
  )

  // let emptyPickup = translate(
  //   Namespace.FREIGHT_BOOKING,
  //   FreightBookingKeys.EMPTY_PICKUP
  // )
  let emptyPickupDateTime = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.EMPTY_PICKUP_DATETIME
  )
  let fullPickupDateTime = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.FULL_PICKUP_DATETIME
  )

  let bookingCreatedOn = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOOKING_CREATED_ON
  )
  let bookingConfirmedOn = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOOKING_CONFIRMED_ON
  )
  let bookingRequestedOn = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.BOOKING_REQUESTED_ON
  )
  let cbm = translate(Namespace.FREIGHT_BOOKING, FreightBookingKeys.CBM)
  let kg = translate(Namespace.FREIGHT_BOOKING, FreightBookingKeys.KG)
  let fullVgmKg = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.FULL_VGM_KG
  )
  let newVesselVoyage = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.NEW_VESSEL_VOYAGE
  )
  let vesselVoyage = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.VESSEL_VOYAGE
  )
  let vesselSearch = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.VESSEL_SEARCH
  )
  let nothingHereYet = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.NOTHING_HERE_YET
  )
  let noItemHereYet = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.NO_ITEM_HERE_YET
  )
  let noItemDescShipper = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.NO_ITEM_DESC_SHIPPER
  )
  let noItemDescCarrier = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.NO_ITEM_DESC_CARRIER
  )
  let smthWrong = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SMTH_WRONG
  )
  let smthWrongDesc = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SMTH_WRONG_DESC
  )
  let refresh = translate(Namespace.FREIGHT_BOOKING, FreightBookingKeys.REFRESH)
  let createANewBooking = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CREATE_A_NEW_BOOKING
  )

  let showScheduleDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SHOW_SCHEDULE_DETAILS
  )
  let hideScheduleDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.HIDE_SCHEDULE_DETAILS
  )
  let showShipmentDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SHOW_SHIPMENT_DETAILS
  )
  let hideShipmentDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.HIDE_SHIPMENT_DETAILS
  )
  let showContainerDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SHOW_CONTAINER_DETAILS
  )
  let hideContainerDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.HIDE_CONTAINER_DETAILS
  )
  let showReeferDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SHOW_REEFER_DETAILS
  )
  let hideReeferDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.HIDE_REEFER_DETAILS
  )
  let showCargoDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SHOW_CARGO_DETAILS
  )
  let hideCargoDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.HIDE_CARGO_DETAILS
  )
  let showDgCargoDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SHOW_DG_CARGO_DETAILS
  )
  let hideDgCargoDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.HIDE_DG_CARGO_DETAILS
  )
  let showContactDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SHOW_CONTACT_DETAILS
  )
  let hideContactDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.HIDE_CONTACT_DETAILS
  )
  let showPaymentDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SHOW_PAYMENT_DETAILS
  )
  let hidePaymentDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.HIDE_PAYMENT_DETAILS
  )
  let dgDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.DETAILS_DG
  )
  let showDgDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SHOW_DETAILS_DG
  )
  let hideDgDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.HIDE_DETAILS_DG
  )

  let weightKgs = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.WEIGHT_KGS
  )
  let weightLbs = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.WEIGHT_LBS
  )
  let reportableQty = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.REPORTABLE_QTY
  )
  let transportDgLimitQuant = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.TRANSPORT_DG_LIMIT_QUANT
  )
  let emptyUncleanedReceptable = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.EMPTY_UNCLEANED_RECEPTABLE
  )
  let exceptedQty = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.EXCEPTED_QTY
  )
  let packingDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.PACKING_DETAILS
  )
  let carrier = translate(Namespace.FREIGHT_BOOKING, FreightBookingKeys.CARRIER)
  let netWeightNoUom = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.NET_WEIGHT_NO_UOM
  )
  let grossWeightNoUom = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.GROSS_WEIGHT_NO_UOM
  )
  let changesDetected = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.CHANGES_DETECTED
  )
  let fileName = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.FILE_NAME
  )
  let showDocumentDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.SHOW_DOCUMENT_DETAILS
  )
  let hideDocumentDetails = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.HIDE_DOCUMENT_DETAILS
  )

  let archived = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.ARCHIVED
  )

  let booking = translate(Namespace.FREIGHT_BOOKING, FreightBookingKeys.BOOKING)

  // Company Preference
  let bookingCompanyPreference = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreferenceKeys.BOOKING_PREF_TITLE
  )
  let closeBookingUpdate = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreferenceKeys.CLOSE_BOOKING_UPDATE
  )

  return {
    title,
    showDocumentDetails,
    hideDocumentDetails,
    fileName,
    changesDetected,
    vgmWeight,
    netWeightNoUom,
    grossWeightNoUom,
    carrier,
    packingDetails,
    reportableQty,
    transportDgLimitQuant,
    emptyUncleanedReceptable,
    exceptedQty,
    dgDetails,
    showDgDetails,
    hideDgDetails,
    bookFromScheduleTitle,
    stepLabelCreateBooking,
    stepLabelReviewBooking,
    stepLabelBookingSubmitted,
    freightBookingId,
    freightBookingRefNo,
    bookingId,
    lastModifiedOn,
    stepLabelAmendBooking,
    stepLabelReviewAmendments,
    stepLabelAmendmentsSubmitted,
    stepLabelSubmit,
    bookingRefNo,
    shipperRefNo,
    containersType,
    departingOn,
    cutoff,
    shipper,
    saveDraftSuccess,
    submitSuccess,
    copySuccess,
    cancelSuccess,
    editSuccess,
    deleteSuccess,
    archivedSuccess,
    approveSuccess,
    rejectSuccess,
    amendSuccess,
    helpdeskAmendBookingStatusSuccess,

    bookingStatus,

    bookingType,
    shipmentType,
    hazardous,
    hazardIdentification,
    radioactiveMeasurement,
    acidConcentration,
    marinePollutantStatus,
    tremCardNo,
    regulatoryInfo,
    radioactiveGoodsInfo,
    placardInfo,
    additionalHazmatSettings,
    poisonInhalationHazardZone,
    aggregationState,
    dateOfFumigation,
    localCategory,
    physicalChemicalProps,
    flashpoint,
    boilingPoint,
    sadt,
    controlTemp,
    emergencyTemp,
    forwardHubScheme,
    outboundRefNo,
    additionalBookingTypeDetails,

    schedule,
    carrierNvoccBookingAgent,
    portOfLoading,
    portOfDischarge,
    polPlannedEta,
    podPlannedEta,
    polPlannedEtd,
    podPlannedEtd,
    loadTerminal,
    dischargeTerminal,
    vesselName,
    internationalVoyageNumber,
    voyageNumber,
    voyageNo,
    additionalScheduleDetails,
    PoRETDAndPoDETAWarningMessage,
    PoRETDWarningMessage,
    PoDETAWarningMessage,

    containerDetails,
    oldContainer,
    newContainer,
    deleted,
    removed,
    added,
    updated,
    renamed,
    quantity,
    type,
    size,
    containerSizeType,
    heightType,
    status,
    shipperOwned,
    addAContainer,
    cloneContainer,

    reeferDetails,
    nonActiveReefer,
    equipmentTemp,
    tempUom,
    specialHandling,
    superFreezerService,
    gensetRequired,
    inTransitColdSterilization,
    numberOfTempProbes,
    numberOfUSDProbes,
    tempVariance,
    ventSetting,
    equipmentAirFlow,
    equipmentAirFlowUom,
    equipmentControlledAtmosphere,
    equipmentControlledAtmosphereCO2,
    equipmentControlledAtmosphereN2,
    equipmentControlledAtmosphereO2,
    humidty,
    equipmentHumidity,
    humidityUom,
    equipmentComments,
    additionalReeferDetails,
    percentofMoistureInAir,

    cargo,
    dgCargo,
    cargoId,
    gross,
    net,
    vgm,
    cargoDesc,
    hsCode,
    additionalCargoDetails,
    addCargo,
    addDGDetails,
    hazardousDetails,
    packageType,
    cbm,
    kg,
    fullVgmKg,
    grossVolume,
    grossWeight,
    dgGrossWeight,
    netVolume,
    netWeight,
    totalVgmWeight,
    totalCargoWeight,
    grossWeightNoUnit,
    netWeightNoUnit,
    grossVolumeNoUnit,
    netVolumeNoUnit,
    marksAndNumbers,
    technicalName,
    properShippingName,
    IMDGCodePage,
    regulationPageNumber,
    regulationVersionNumber,
    EMSNumber,
    uom,
    limitedQTY,
    expectedQTY,
    additionalClassIdenDetails,
    additionalPackWeightDetails,
    additionalHazardIdenDetails,
    additionalPhysiChemiDetails,
    packingGroup,
    ibcPackageCode,
    packagingDetails,
    generalComments,
    fumigationType,
    amountOfFumigation,
    inhalantHazard,
    emptyUncleanedReceptacle,
    transportOfDangerousGoodsInLimitedQuantities,
    reportableQuantity,
    netExplosiveContent,
    weightKgs,
    weightLbs,

    shipment,
    moveType,
    emptyPickup,
    emptyPickupInstruction,
    shipTo,
    shipFrom,
    placeOfReceipt,
    placeOfDelivery,
    cargoReadyDate,
    cargoDeliveryDate,
    placeOfReceiptDesc,
    placeOfDeliveryDesc,
    placeOfReceiptETD,
    placeOfDeliveryETA,
    destinationCode,
    finalDestination,
    compName,
    company,
    compAddress,
    ContactName,
    contact,
    ContactPhoneNo,
    emptyPickupDate,
    fullPickupDate,
    shipFromDatePickup,
    shipFromDatePositioning,
    shipToDateDelivery,
    additionalShipmentDetails,

    document,
    documents,
    attachDocument,
    documentInstruction,
    fileType,
    fileDesc,

    contactDetails,
    additionalContactDetails,
    representativeName,
    representativeEmail,
    representativeContactNumber,
    references,
    contractNumber,
    purchaseOrderNumber,
    shipperRefNumber,
    forwarderRefNumber,
    preAssignmentBookingNumber,
    parties,
    contractPartyName,
    mainNotifyParty,
    shipperName,
    consigneeName,
    forwarderName,
    preShipmentNotificationContacts,
    addContactDetails,
    partnerEmailNtf,
    emailToNotify,
    notifyBookingStatus,
    notifyEventMilestones,
    partnerEmailCaption,

    paymentInstruction,
    chargeType,
    payer,
    freightTerm,
    pmtLoc,
    shipperRemarks,
    addPaymentInstruction,
    paymentLocation,

    dialogDiscardBookingTitle,
    dialogDiscardBookingContent,
    dialogCancelBookingTitle,
    dialogDeleteBookingTitle,
    dialogArchiveBookingTitle,
    dialogChangeStatusGeneralContent,
    dialogSwitchDGContent,
    formErrorWarning,
    manageBookingTitle,
    viewOnlyBookingTitle,
    backToViewOnlyBookingPage,
    bookingDetailsTitle,

    reviewBooking,
    containers,
    container,
    bookingSubmitted,
    bookingApproved,
    bookingClosed,
    bookingCancelled,
    bookingAmended,
    bookingRejected,
    bookingSubmittedOn,
    bookingApprovedOn,

    myBooking,
    bookingFilterDate,
    lastModifiedDate,
    bookingFilterAll,
    bookingFilterDate15,
    bookingFilterDate30,
    bookingFilterDate60,

    pendingApproval,
    processed,
    shown,
    submittedInfo,
    approvedInfo,
    rejectedInfo,
    amendedInfo,
    cancelledInfo,
    closedInfo,
    shipperComments,
    approval,
    carrierRemarks,
    bookingRefenceNumber,
    approveBooking,
    declineBooking,
    carrierComments,
    classificationIdentication,
    imoClass,
    undgNumber,
    shippingName,
    packagingWeight,
    outerPkgQTY,
    innerPkgQTY,
    forEmergency,
    emergencyContact,
    emergencyContactNumber,
    emptyPickupDateTime,
    fullPickupDateTime,
    bookingCreatedOn,
    bookingConfirmedOn,
    bookingRequestedOn,

    newVesselVoyage,
    vesselVoyage,
    vesselSearch,
    nothingHereYet,
    noItemHereYet,
    noItemDescShipper,
    noItemDescCarrier,
    smthWrong,
    smthWrongDesc,
    refresh,
    createANewBooking,
    showScheduleDetails,
    hideScheduleDetails,
    showShipmentDetails,
    hideShipmentDetails,
    showContainerDetails,
    hideContainerDetails,
    showReeferDetails,
    hideReeferDetails,
    showCargoDetails,
    hideCargoDetails,
    showDgCargoDetails,
    hideDgCargoDetails,
    showContactDetails,
    hideContactDetails,
    showPaymentDetails,
    hidePaymentDetails,
    archived,
    booking,

    bookingCompanyPreference,
    closeBookingUpdate
  }
}

export default FreightBookingTranslationText
