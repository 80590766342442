import { Yup } from 'cng-web-lib'
import * as ValidationRegex from './ValidationRegex'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function DocumentValidationSchema() {

  const validationMessageTranslation = ValidationMessageTranslationText()

  return Yup.object({

    //container
    // bookingDocuments: Yup.array(
    //   Yup.object({
    //     docType: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),
    //     description: Yup.string().required(validationMessageTranslation.requiredMessage).nullable()
    //   })
    // ).nullable(),
    // pendingBookingDocuments: Yup.array(
    //   Yup.object({
    //     docType: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),
    //     description: Yup.string().required(validationMessageTranslation.requiredMessage).nullable()
    //   })
    // ).nullable(),

  })
}

export default DocumentValidationSchema


