import { Yup } from 'cng-web-lib'
import * as ValidationRegex from './ValidationRegex'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function BookingTypeValidationSchema() {


  const validationMessageTranslation = ValidationMessageTranslationText()

  return Yup.object({

    shipmentType: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),

  })
}

export default BookingTypeValidationSchema

